import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Alert,
  Button,
  Container,
  DropdownItem,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap";
import request from "../../../../utils/Request";
import { Link } from "react-router-dom";
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import { isMobile } from "react-device-detect";
import { translate, t } from "react-switch-lang";
export default translate(function ResultAssesmentMsdt(props) {
  const [token, setToken] = useState(null);
  useEffect(() => {
    request
      .get("/token/" + props.result.id)
      .then((res) => {
        setToken(res.data);
      })
      .catch((err) => {});
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // const [name, setName] = useState('Copy Link')
  const diKlik = () => {
    toast.success(t("JobVacancy.alreadyCopy"), { autoClose: 2000 });
  };

  const [dropdownOpen, setOpen] = useState(false);
  const toggle = () => setOpen(!dropdownOpen);
  function title(title) {
    switch (title) {
      case "executive":
        return "Executive";
      case "compromiser":
        return "Compromiser";
      case "benevolent_autocrat":
        return "Benevolent Autocrat";
      case "autocrat":
        return "Autocrat";
      case "developer":
        return "Developer";
      case "missionary":
        return "Missionary";
      case "bureaucrat":
        return "Bureaucrat";
      case "deserter":
        return "Deserter";
      default:
        return "";
    }
  }

  return (
    <Container>
      <div className="justify-content-center" style={{ paddingTop: "2rem" }}>
        <Row>
          <Col sm="2">
            {props.back ? (
              <Link to="/assessment">
                <Button className="navtext btn btn-lg btn-netis-color mb-3">
                  <i className="fa fa-arrow-left mr-2"></i>
                  {t("General.back")}
                </Button>
              </Link>
            ) : null}
          </Col>
          <Col sm="8">
            <Row>
              <Col sm="2" />
              <Col sm="8">
                <Alert
                  color="success"
                  className="text-center mb-3"
                  style={{
                    width: "100%",
                    marginRight: "auto",
                    marginLeft: "auto",
                  }}
                >
                  <h5 className="content-title mb-2">
                    {t("Assessment.completeTest")} <br />{" "}
                    {t("Assessment.leadershipTitle")}.
                  </h5>
                  <p>
                    <b>{props.message}</b>
                  </p>
                </Alert>
                <Col sm="2" />
              </Col>
            </Row>
          </Col>
          <Col sm="2" className="mb-3">
            <ButtonDropdown
              isOpen={dropdownOpen}
              toggle={toggle}
              className="float-right"
            >
              <DropdownToggle
                caret
                className="navtext btn btn-lg btn-netis-color"
              >
                <i className="fa fa-share-alt mr-2"></i>
                {t("Assessment.share")}
              </DropdownToggle>
              <DropdownMenu
                className="mr-4 mt-3"
                style={{ marginLeft: "-120px" }}
              >
                <DropdownItem style={{ border: "none" }}>
                  <FacebookShareButton
                    style={{ marginRight: 50 }}
                    className="sosmed text-left ml-1"
                    url={
                      process.env.REACT_APP_DOMAIN +
                      "/share-assessment-msdt/" +
                      token
                    }
                    quote={
                      "Inilah hasil tes kepemimpinanku. Cek milikmu disini!"
                    }
                  >
                    <FacebookIcon
                      size={32}
                      round={true}
                      style={{ marginRight: "1rem" }}
                    />
                    <b>Facebook</b>
                  </FacebookShareButton>{" "}
                  <br />
                </DropdownItem>
                <DropdownItem style={{ border: "none" }}>
                  <TwitterShareButton
                    style={{ marginRight: 50 }}
                    className="sosmed text-left ml-1"
                    url={
                      process.env.REACT_APP_DOMAIN +
                      "/share-assessment-msdt/" +
                      token
                    }
                    title={
                      "Inilah hasil tes kepemimpinanku. Cek milikmu disini!"
                    }
                  >
                    <TwitterIcon
                      size={32}
                      round={true}
                      style={{ marginRight: "1rem" }}
                    />
                    <b>Twitter</b>
                  </TwitterShareButton>
                  <br />
                </DropdownItem>
                <DropdownItem style={{ border: "none" }}>
                  <WhatsappShareButton
                    style={{ marginRight: 50 }}
                    className="sosmed text-left ml-1"
                    url={
                      process.env.REACT_APP_DOMAIN +
                      "/share-assessment-msdt/" +
                      token
                    }
                    separator={
                      "Inilah hasil tes kepemimpinanku. Cek milikmu disini!"
                    }
                  >
                    <WhatsappIcon
                      size={32}
                      round={true}
                      style={{ marginRight: "1rem" }}
                    />
                    <b>WhatsApp</b>
                  </WhatsappShareButton>
                </DropdownItem>
                <DropdownItem style={{ border: "none" }} onClick={diKlik}>
                  <CopyToClipboard
                    className="text-left ml-2"
                    text={
                      process.env.REACT_APP_DOMAIN +
                      "/share-assessment-msdt/" +
                      token
                    }
                  >
                    <div>
                      <img
                        src={require("../../../../assets/img/copy.png")}
                        alt=""
                        style={{ width: "25px" }}
                      />
                      <b style={{ cursor: "pointer", marginLeft: "20px" }}>
                        Copy Link
                      </b>
                    </div>
                  </CopyToClipboard>
                </DropdownItem>
              </DropdownMenu>
            </ButtonDropdown>
          </Col>
        </Row>

        <Row>
          <Col sm="2" />
          <Col sm="8">
            <div className="text-center">
              <Row>
                {isMobile ? (
                  <>
                    <Col sm="12">
                      <img
                        src={require("../../../../assets/img/msdt/mobile/" +
                          props.result.type +
                          ".png")}
                        alt={props.result.type}
                        style={{ width: "100%", marginBottom: "40px" }}
                      />
                      <br />
                      <Alert
                        color="info"
                        className="text-left mt-4 mb-2"
                        style={{
                          width: "100%",
                          marginLeft: "auto",
                          marginRight: "auto",
                        }}
                      >
                        <h3 style={{ color: "#1B568D", fontWeight: "bold" }}>
                          {t("Assessment.leadershipType")}{" "}
                          {title(props.result.type)}
                        </h3>
                        <p className="mb-2" style={{ color: "#555" }}>
                          {props.result.description}
                          <ul>
                            {props.result.lists.map((data, idx) => {
                              return <li key={idx}>{data}</li>;
                            })}
                          </ul>
                        </p>
                      </Alert>
                    </Col>
                  </>
                ) : (
                  <>
                    <Col sm="2" />
                    <Col sm="8">
                      <Alert
                        color="info"
                        className="text-left"
                        style={{
                          width: "100%",
                          marginLeft: "auto",
                          marginRight: "auto",
                          marginTop: "70px",
                          zIndex: 999,
                        }}
                      >
                        <h2
                          style={{ color: "#1B568D", fontWeight: "bold" }}
                          className="text-center"
                        >
                          <span style={{ fontSize: "0.8em" }}>
                            {t("Assessment.leadershipType")}:
                          </span>
                          <br /> {title(props.result.type)}
                        </h2>
                        <p className="mb-2" style={{ color: "#555" }}>
                          {props.result.description}
                          <ul>
                            {props.result.lists.map((data, idx) => {
                              return <li key={idx}>{data}</li>;
                            })}
                          </ul>
                        </p>
                      </Alert>
                    </Col>
                    <Col sm="2" />
                    <Col sm="12">
                      <img
                        src={require("../../../../assets/img/msdt/web/" +
                          props.result.type +
                          ".png")}
                        alt={props.result.type}
                        style={{ width: "100%", marginTop: "-100px" }}
                      />
                    </Col>
                  </>
                )}
              </Row>
            </div>
            <br />
          </Col>
          <Col sm="2" />
        </Row>
      </div>
    </Container>
  );
});
