import React, { Fragment } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import ProfileStandart from "./ProfileStandart";
import ProfileProfesional from "./ProfileProfesional";
import { translate } from "react-switch-lang";

toast.configure();
const MainProfile = (props) => {
  return (
    <Fragment>
      <div className="animated fadeIn">
        <div className="content-body">
          <ProfileStandart />
          <br />
          <br />
          <div className="step-profile-69">
            <ProfileProfesional />
          </div>
          <br />
          <br />
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    sessionId: state.sessionId,
  };
};
const mapsToDispatch = (dispatch) => {
  return {
    setUser: (state) =>
      dispatch({
        type: "SET_USER",
        payload: state,
      }),
  };
};

export default connect(mapStateToProps, mapsToDispatch)(translate(MainProfile));
