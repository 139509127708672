export const SET_LOADER = "SET_LOADER";
export const SET_USER = "SET_USER";
export const setLoader = ({ state }) => ({
    type: SET_LOADER,
    payload: state
});

export const setUser = ({ user }) => ({
    type: SET_USER,
    payload: user
});