import React, { Fragment } from "react";
import { Row, Col, Spinner } from "reactstrap";
const LoadingAnimation = () => {
  return (
    <Fragment>
      <Row>
        <Col sm="12" className="text-center pt-5">
          <h1>
            {" "}
            <Spinner size="lg" className="mr-2" /> Loading
          </h1>
        </Col>
      </Row>
    </Fragment>
  );
};

export default LoadingAnimation;
