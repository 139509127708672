import React from "react";
import ReactDOM from "react-dom";

import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import { SWRConfig } from "swr";
import request from "./utils/Request";
import * as Sentry from "@sentry/browser";
if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://1b5a270617da4c35931db44f2c6723ba@o404034.ingest.sentry.io/5267898",
  });
}

const rootElement = document.getElementById("root");
ReactDOM.render(
  <Router>
    <SWRConfig value={{ fetcher: (...args) => request.get(...args) }}>
      <App />
    </SWRConfig>
  </Router>,
  rootElement
);
