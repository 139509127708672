import React, { useState, useEffect } from "react";
import UserGuide from "../../../../Templates/BusinessInsightTest/UserGuide";
import BusinessInsightResult from "../../../../Templates/ResultAssessment/ResultAssessmentBusinessInsight";
import LoadingAnimation from "../../../../../components/atoms/LoadingAnimation";
import request from "../../../../../utils/Request";
import requestGeneral from "../../../../../utils/RequestGeneral";
import NoComplete from "../NoCompleteProfile";
import NotFound from "../../../NotFound";
function BusinessInsight() {
  const [loading, setLoading] = useState(true);
  const [isComplete, setComplete] = useState(false);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState(null);
  const [result, setResult] = useState([]);
  const [redirect, setRedirect] = useState(false);
  useEffect(() => {
    if (localStorage.getItem("referal")) {
      requestGeneral
        .get("internal/recruitment/internal/" + localStorage.getItem("referal"))
        .then((res) => {
          if (
            res?.data?.data?.requirements?.filter((item) =>
              item?.toLowerCase().includes("bussiness-insight")
            ).length === 0
          ) {
            setRedirect(true);
            setLoading(false);
          } else if (
            res?.data?.data?.requirements?.filter((item) =>
              item?.toLowerCase().includes("profile")
            ).length > 0
          ) {
            validate();
          } else {
            getBusiness();
          }
        })
        .catch((err) => {
          getBusiness();
        });
    } else {
      getBusiness();
    } // eslint-disable-next-line
  }, []);
  function getBusiness() {
    request
      .get("/assessment/test/business-insight/result")
      .then((res) => {
        setResult(res.data.data);
        setComplete(true);
        setLoading(false);
        setMessage(res.data.message);
      })
      .catch((err) => {
        setLoading(false);
        setComplete(false);
      });
  }
  function validate() {
    requestGeneral
      .get(`/v2/users/validate-standart`)
      .then((res) => {
        getBusiness();
      })
      .catch((err) => {
        if (err.response?.status === 422) {
          setError(true);
          setLoading(false);
        } else {
          setLoading(false);
          return Promise.reject(err);
        }
      });
  }
  return (
    <>
      {loading ? (
        <LoadingAnimation />
      ) : redirect ? (
        <NotFound />
      ) : error ? (
        <NoComplete />
      ) : isComplete ? (
        <BusinessInsightResult result={result} back={true} message={message} />
      ) : (
        <UserGuide />
      )}
    </>
  );
}

export default BusinessInsight;
