import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Row,
  Alert,
  Col,
  Container,
  CardDeck,
  CardImg,
  CardBody,
  CardTitle,
  CardText,
  Button,
} from "reactstrap";
import { LeftLargeRibbon, RibbonContainer } from "react-ribbons";
import FooterLandingPage from "../../../Templates/LandingPage/FooterLandingPage";
import { Link } from "react-router-dom";
import Colors from "../../../../utils/Colors";
import request from "../../../../utils/Request";
import LoadingSkeleton from "../../../Templates/SkeletonLoading/Menu/Assesment";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import CaptureLoading from "../../../../components/atoms/CaptureLoading";
import requestGeneral from "../../../../utils/RequestGeneral";
import Tour from "reactour";
import { translate } from "react-switch-lang";
import InternalModal from "../../../../components/atoms/InternalModal";
import NoUserInternalModal from "../../../../components/atoms/NoUserInternalModal";
import { toast } from "react-toastify";
toast.configure();

const Assessment = (props) => {
  const { t } = props;
  const [loading, setLoading] = useState(true);
  const [expire, setExpire] = useState(false);
  const [mbtiExpire, setMbtiExpire] = useState(false);
  const [papiExpire, setPapiExpire] = useState(false);
  const [discExpire, setDiscExpire] = useState(false);
  const [msdtExpire, setMsdtExpire] = useState(false);
  const [spmExpire, setSpmExpire] = useState(false);
  const [agilityExpire, setAgilityExpire] = useState(false);
  const [businessExpire, setuBusinessExpire] = useState(false);
  const [loadImg, setLoadImg] = useState(false);
  const disableBody = (target) => disableBodyScroll(target);
  const enableBody = (target) => enableBodyScroll(target);
  const accentColor = "#93aad6";
  const [isTourOpen, setIsTourOpen] = useState(false);
  const [loadingPopup, setLoadingPopup] = useState(false);
  const [id, setId] = useState(null);
  const [job, setJob] = useState(null);
  const [company, setCompany] = useState(null);
  const [modal, setModal] = useState(true);
  const [modalSub, setModalSub] = useState(false);
  const [requirement, setRequirement] = useState([]);

  useEffect(() => {
    requestGeneral
      .get("internal/recruitment/internal/" + localStorage.getItem("referal"))
      .then((res) => {
        setRequirement(res?.data?.data?.requirements);
        setCompany(res?.data?.data?.company_name);
        setJob(res?.data?.data?.name);
        setId(res?.data?.data?.id);
        requestGeneral
          .get(
            "internal/recruitment/internal/user/" +
              localStorage.getItem("referal") +
              "/" +
              res?.data?.data?.companyPaid
          )
          .then((res) => {
            if (!res?.data?.existSkillanaId && res?.data?.isSub) {
              setModal(true);
              setLoading(false);
            } else {
              setModal(false);
              setLoading(false);
            }
          })
          .catch((err) => {
            if (err?.response?.data?.isSub) {
              setRequirement([]);
              setCompany(null);
              setJob(null);
              setId(null);
              localStorage.removeItem("referal");
              setModal(false);
              setModalSub(true);
              setLoading(false);
            } else {
              setModal(true);
              setLoading(false);
            }
          });
      })
      .catch((err) => {
        setModal(false);
        expireAsessment();
      });
  }, []);

  function expireAsessment() {
    request
      .get("/users/expire-assessment")
      .then((res) => {
        setExpire(true);
        setLoading(false);
        setMbtiExpire(res.data.mbti === true ? "mbti" : false);
        setPapiExpire(res.data.papi === true ? "papi" : false);
        setDiscExpire(res.data.disc === true ? "disc" : false);
        setMsdtExpire(res.data.msdt === true ? "msdt" : false);
        setSpmExpire(res.data.spm === true ? "spm" : false);
        setAgilityExpire(res.data.agility === true ? "agility" : false);
        setuBusinessExpire(
          res.data.businessInsight === true ? "businessInsight" : false
        );
      })
      .catch((err) => {
        setExpire(false);
        setLoading(false);
      });
  }

  useEffect(() => {
    if (props.user) {
      if (props.user.guideAssessment === 0) {
        setIsTourOpen(true);
      }
    }
  }, [props.user]);

  function removeInternal() {
    localStorage.removeItem("referal");
    setModal(false);
  }

  function logoutInternal() {
    localStorage.removeItem("referal");
    window.location.reload();
  }

  function removeInternalSub() {
    localStorage.removeItem("referal");
    setModalSub(false);
  }

  function regisInternal() {
    setLoadingPopup(true);
    requestGeneral
      .post("/internal/recruitment/vacancy/" + id)
      .then((res) => {
        setLoadingPopup(false);
        setModal(false);
        toast.success("Success");
      })
      .catch((err) => {
        setLoadingPopup(false);
        toast.error("Error");
      });
  }

  const steps = [
    {
      selector: ".step-assesment",
      content: ({ goTo, inDOM }) => (
        <div>
          <p>{t("UserGuide.assessmentMenu")}</p>
          <div className="col-12 text-center">
            <Row>
              <div className="col-12 text-center p-0">
                <Button
                  className="mt-2"
                  type="submit"
                  color="netis-color"
                  onClick={() => {
                    disableUserGuide();
                    enableBody();
                    setIsTourOpen(false);
                  }}
                >
                  {t("General.okey")}
                </Button>
              </div>
            </Row>
          </div>
        </div>
      ),
    },
  ];
  const menuList = [
    {
      name: "mbti",
      title: t("Assessment.aptitudeTitle"),
      desc: t("Assessment.aptitudeDesc"),
      image: "MBTI",
      link: "/assessment-mbti",
      internal: localStorage.getItem("referal")
        ? requirement?.filter((item) => item?.toLowerCase().includes("mbti"))
            .length > 0
        : true,
    },
    {
      name: "disc",
      title: t("Assessment.personalityTitle"),
      desc: t("Assessment.personalityDesc"),
      image: "disc",
      link: "/assessment-disc",
      internal: localStorage.getItem("referal")
        ? requirement?.filter((item) => item?.toLowerCase().includes("disc"))
            .length > 0
        : true,
    },
    {
      name: "msdt",
      title: t("Assessment.leadershipTitle"),
      desc: t("Assessment.leadershipDesc"),
      image: "MSDT",
      link: "/assessment-msdt",
      internal: localStorage.getItem("referal")
        ? requirement?.filter((item) => item?.toLowerCase().includes("msdt"))
            .length > 0
        : true,
    },
    {
      name: "papi",
      title: t("Assessment.workStyleTitle"),
      desc: t("Assessment.workStyleDesc"),
      image: "papikostick",
      link: "/assessment-papikostik",
      internal: localStorage.getItem("referal")
        ? requirement?.filter((item) =>
            item?.toLowerCase().includes("papikostick")
          ).length > 0
        : true,
    },
    // {
    //   name: "spm",
    //   title: "Tes IQ",
    //   desc:
    //     "Ketahui kemampuanmu secara intelektual, analisis dan menggunakan logika dalam memecahkan masalah.",
    //   image: "SPM",
    //   link: "/assessment-spm",
    // },

    {
      name: "businessInsight",
      title: t("Assessment.businessInsightTitle"),
      desc: t("Assessment.businessInsightDesc"),
      image: "businessInsight",
      link: "/assessment-business-insight",
      internal: localStorage.getItem("referal")
        ? requirement?.filter((item) =>
            item?.toLowerCase().includes("bussiness-insight")
          ).length > 0
        : true,
    },
    {
      name: "agility",
      title: t("Assessment.agilityTitle"),
      desc: t("Assessment.agilityDesc"),
      image: "agility",
      link: "/assessment-agility",
      internal: localStorage.getItem("referal")
        ? requirement?.filter((item) => item?.toLowerCase().includes("agility"))
            .length > 0
        : true,
    },
    {
      name: "fingerprint",
      title: t("Assessment.biometricTitle"),
      desc: t("Assessment.biometricDesc"),
      image: "biometrik",
      link: "/assessment-fingerprint",
      internal: localStorage.getItem("referal") ? false : true,
    },
  ];
  function disableUserGuide() {
    request
      .put("/users/disable_user_guide_assessment")
      .then((res) => {
        props.setUser({
          ...props.user,
          guideAssessment: 1,
        });
      })
      .catch((err) => {
        props.setUser({
          ...props.user,
          guideAssessment: 1,
        });
      });
  }
  if (loadingPopup) {
    return <CaptureLoading title="Loading" visible={loadingPopup} />;
  }

  const internalCardWrapper = document.getElementsByClassName(
    "internal-card-wrapper"
  )[0];

  return (
    <div className="animated fadeIn">
      <Container className="mt-3">
        <Row className="mb-1">
          {loading ? null : localStorage.getItem("referal") ? (
            <Col sm="12" className="text-center">
              <Alert color="info" className="text-center mt-3 mb-4">
                <h5 className="content-title mb-2">
                  {t("Internal.alert1")} {job} {t("Internal.alert2")} {company}
                </h5>
                <br />
                <Button
                  className="btn button-outline-red"
                  onClick={logoutInternal}
                >
                  <i className="fa fa-sign-out mr-2"></i>
                  {t("Internal.logout")} {job}
                </Button>
              </Alert>
            </Col>
          ) : (
            <Col sm="4">
              <Link to="/home">
                <button className="navtext btn btn-netis-color mb-3">
                  <i className="fa fa-arrow-left mr-2"></i>
                  {t("General.back")}
                </button>
              </Link>
            </Col>
          )}
        </Row>
        {loading ? (
          <div className=" pb-5">
            <LoadingSkeleton />
          </div>
        ) : (
          <div>
            {!localStorage.getItem("referal") ? (
              <Tour
                steps={steps}
                accentColor={accentColor}
                showButtons={false}
                rounded={5}
                disableDotsNavigation={true}
                showNavigation={false}
                isOpen={isTourOpen}
                disableFocusLock={false}
                onAfterOpen={disableBody}
                onBeforeClose={enableBody}
                updateDelay={6}
                onRequestClose={() => {
                  disableUserGuide();
                  setIsTourOpen(false);
                }}
              />
            ) : null}
            <NoUserInternalModal
              isOpen={modalSub}
              onRight={removeInternalSub}
            />
            <Row className="mb-5">
              {localStorage.getItem("referal") ? (
                <InternalModal
                  isOpen={modal}
                  company={company}
                  job={job}
                  onLeft={removeInternal}
                  onRight={regisInternal}
                />
              ) : null}

              <Col md="12" className="menu step-assesment">
                <CardDeck className="justify-content-center">
                  <div style={{ display: "inherit", marginTop: "10px" }}>
                    <Row
                      className={
                        internalCardWrapper?.childElementCount > 4
                          ? "internal-card-wrapper justify-content-start"
                          : "internal-card-wrapper justify-content-center"
                      }
                    >
                      {menuList.map((data) => {
                        if (data?.internal) {
                          // eslint-disable-line react-hooks/exhaustive-deps
                          return (
                            <>
                              <Col
                                lg={
                                  internalCardWrapper?.childElementCount < 2
                                    ? "4"
                                    : "3"
                                }
                                sm="6"
                                xs="6"
                                className="p-1 mb-3"
                              >
                                <Link
                                  to={
                                    !localStorage.getItem("referal")
                                      ? props.user.guideAssessment === 0
                                        ? "#"
                                        : data.link
                                      : data.link
                                  }
                                  className="card menu-item h-100 pb-2"
                                >
                                  <RibbonContainer className="custom-class">
                                    {!localStorage.getItem("referal") ? (
                                      expire ? (
                                        mbtiExpire === data.name ||
                                        papiExpire === data.name ||
                                        discExpire === data.name ||
                                        spmExpire === data.name ||
                                        msdtExpire === data.name ||
                                        agilityExpire === data.name ||
                                        businessExpire === data.name ? (
                                          <LeftLargeRibbon
                                            backgroundColor="#cc0000"
                                            color="#fff"
                                            wid
                                          >
                                            <b>{t("Dashboard.expire")}</b>
                                          </LeftLargeRibbon>
                                        ) : null
                                      ) : null
                                    ) : null}
                                    <CardImg
                                      top
                                      width="86%"
                                      src={require("../../../../assets/img/" +
                                        data.image +
                                        ".png")}
                                      alt="Card image cap"
                                      className="p-3"
                                      onLoad={() => setLoadImg(true)}
                                    />
                                    {loadImg ? null : (
                                      <div
                                        style={{
                                          background: "transparent",
                                          height: "86%",
                                          width: "70%",
                                        }}
                                      />
                                    )}
                                    <CardBody>
                                      <CardTitle
                                        className="mb-2 title-menu-company"
                                        style={{ color: Colors.primaryColor }}
                                      >
                                        <h4 className="font-weight-bold">
                                          {data.title}
                                        </h4>
                                      </CardTitle>
                                      <CardText style={{ fontSize: "0.9em" }}>
                                        {data.desc}
                                      </CardText>
                                    </CardBody>
                                  </RibbonContainer>
                                </Link>
                              </Col>
                            </>
                          ); // eslint-disable-line react-hooks/exhaustive-deps
                        } else return null;
                      })}
                    </Row>
                  </div>
                </CardDeck>
              </Col>
            </Row>
          </div>
        )}
      </Container>
      <FooterLandingPage />
    </div>
  );
};

const mapsToDispatch = (dispatch) => {
  return {
    setUser: (state) =>
      dispatch({
        type: "SET_USER",
        payload: state,
      }),
  };
};
export default connect(
  ({ user }) => ({ user }),
  mapsToDispatch
)(translate(Assessment));
