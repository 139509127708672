import { useMemo } from "react";
import useSWR from "swr";

export function useInvoiceAgility(defaultData = []) {
  const { data: response, error } = useSWR("/assessment/test/agility/invoice", {
    refreshInterval: 1000000,
  });

  const loading = !response && !error;

  const data = useMemo(() => {
    if (response) {
      return response.data;
    }
    return defaultData;
  }, [response, defaultData]);

  return { loading, data, error };
}
