import React, { useEffect, useRef, useState, Fragment } from "react";
import moment from "../../.../../../../utils/Moment";
import { Radar } from "react-chartjs-2";
import {
  CardBody,
  Card,
  Row,
  Container,
  Alert,
  Col,
  Collapse,
  Button,
  DropdownItem,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { t, translate } from "react-switch-lang";
import "./papikostickresult.css";
import Star from "../../../../components/molecules/Star";
import { Link } from "react-router-dom";
import request from "../../../../utils/Request";
import "chartjs-plugin-datalabels";
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";
import { toast } from "react-toastify";
import langUtils from "../../../../utils/SettingLanguage/index";
moment.locale("id");
function PapikostickResult({ result, back }) {
  const groupingLabel = {
    F: { category: "Followership", color: "#e53935" },
    W: { category: "Followership", color: "#e53935" },
    N: { category: "Work Direction", color: "#8e24aa" },
    G: { category: "Work Direction", color: "#8e24aa" },
    A: { category: "Work Direction", color: "#8e24aa" },
    L: { category: "Leadership", color: "#3949ab" },
    P: { category: "Leadership", color: "#3949ab" },
    I: { category: "Leadership", color: "#3949ab" },
    T: { category: "Activity", color: "#039be5" },
    V: { category: "Activity", color: "#039be5" },
    X: { category: "Social Nature", color: "#00897b" },
    S: { category: "Social Nature", color: "#00897b" },
    B: { category: "Social Nature", color: "#00897b" },
    O: { category: "Social Nature", color: "#00897b" },
    C: { category: "Work Style", color: "#7cb342" },
    D: { category: "Work Style", color: "#7cb342" },
    R: { category: "Work Style", color: "#7cb342" },
    Z: { category: "Temperament", color: "#fb8c00" },
    E: { category: "Temperament", color: "#fb8c00" },
    K: { category: "Temperament", color: "#fb8c00" },
  };
  const colorsByIndex = Object.keys(groupingLabel).map(
    (label) => groupingLabel[label].color
  );
  const [token, setToken] = useState(null);

  useEffect(() => {
    request
      .get("/token/" + result.id)
      .then((res) => {
        setToken(res.data);
      })
      .catch((err) => {});
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  const data = {
    labels: "FWNGALPITVXSBORDCZEK".split(""),
    datasets: [
      {
        label: "RESULT",
        borderWidth: 1,
        pointRadius: 2,
        backgroundColor: "rgba(0,0,0,0.3)",
        borderColor: (option) => {
          // console.log(option);
          return colorsByIndex[option.dataIndex];
        },
        pointBackgroundColor: (option) => colorsByIndex[option.dataIndex],
        pointBorderColor: (option) => colorsByIndex[option.dataIndex],
        data: "FWNGALPITVXSBORDCZEK".split("").map((huruf) => {
          if (huruf === "Z") {
            return 9 - result.scores[huruf];
          } else if (huruf === "K") {
            return 9 - result.scores[huruf];
          } else {
            return result.scores[huruf];
          }
        }),
      },
    ],
  };

  const options = {
    plugins: {
      datalabels: {
        display: false,
        backgroundColor: (option) => colorsByIndex[option.dataIndex],
        color: "#fff",
        font: {
          weight: "bold",
          size: 11,
        },
        borderColor: "#fff",
        borderWidth: 2,
        padding: {
          top: 6,
          bottom: 5,
          left: 8,
          right: 8,
        },
        borderRadius: 999,
      },
    },
    tooltips: {
      callbacks: {
        title: (tooltipItem, data) => {
          // console.log(tooltipItem, data);
          return (
            groupingLabel[data.labels[tooltipItem[0].index]].category +
            " (" +
            data.labels[tooltipItem[0].index] +
            ")"
          );
        },
        label: (tooltipItem) => parseInt(tooltipItem.value),
      },
    },

    legend: {
      display: false,
    },
    title: {
      display: false,
      text: t("Assessment.workStyle"),
    },
    scale: {
      gridLines: {
        display: false,
        circular: true,
      },
      angleLines: {
        // display: false,
      },
      ticks: {
        display: false,
        max: 9,
        min: 0,
        stepSize: 1,
        beginAtZero: true,
        showLabelBackdrop: true,
      },
      pointLabels: {
        display: false,
        fontStyle: "bold",
        fontSize: 12,
        fontColor: Object.keys(groupingLabel).map(
          (label) => groupingLabel[label].color
        ),
      },
    },
  };
  const chartRef = useRef(null);
  const diKlik = () => {
    toast.success(t("JobVacancy.alreadyCopy"), { autoClose: 2000 });
  };
  const [dropdownOpen, setOpen] = useState(false);
  const toggle = () => setOpen(!dropdownOpen);

  return (
    <>
      <Container>
        <div className="justify-content-center" style={{ paddingTop: "2rem" }}>
          <Row>
            <Col sm="3">
              {back ? (
                <Link to="/assessment">
                  <Button className="navtext btn btn-lg btn-netis-color mb-3 ">
                    <i className="fa fa-arrow-left mr-2"></i>
                    {t("General.back")}
                  </Button>
                </Link>
              ) : null}
            </Col>
            <Col sm="6">
              <Alert color="success" className="text-center mb-3">
                <h5 className="content-title mb-2">
                  <b>
                    {t("Assessment.completeTest")} <br />{" "}
                    {t("Assessment.workStyleTitle")}.
                  </b>
                </h5>
                <p>
                  {result.result_expired_at && (
                    <p>
                      <b>{result.message}</b>
                    </p>
                  )}
                </p>
              </Alert>
            </Col>
            <Col sm="3" className="mb-2">
              <ButtonDropdown
                isOpen={dropdownOpen}
                toggle={toggle}
                className="float-right"
              >
                <DropdownToggle
                  caret
                  className="navtext btn btn-lg btn-netis-color"
                >
                  <i className="fa fa-share-alt mr-2"></i>
                  {t("Assessment.share")}
                </DropdownToggle>
                <DropdownMenu
                  className="mr-4 mt-3"
                  style={{ marginLeft: "-120px" }}
                >
                  <DropdownItem style={{ border: "none" }}>
                    <FacebookShareButton
                      style={{ marginRight: 50 }}
                      className="sosmed text-left ml-1"
                      url={
                        process.env.REACT_APP_DOMAIN +
                        "/share-assessment-papikostick/" +
                        token
                      }
                      quote={
                        "Inilah hasil tes gaya kerjaku. Cek milikmu disini!"
                      }
                    >
                      <FacebookIcon
                        size={32}
                        round={true}
                        style={{ marginRight: "1rem" }}
                      />
                      <b>Facebook</b>
                    </FacebookShareButton>{" "}
                    <br />
                  </DropdownItem>
                  <DropdownItem style={{ border: "none" }}>
                    <TwitterShareButton
                      style={{ marginRight: 50 }}
                      className="sosmed text-left ml-1"
                      url={
                        process.env.REACT_APP_DOMAIN +
                        "/share-assessment-papikostick/" +
                        token
                      }
                      title={
                        "Inilah hasil tes gaya kerjaku. Cek milikmu disini!"
                      }
                    >
                      <TwitterIcon
                        size={32}
                        round={true}
                        style={{ marginRight: "1rem" }}
                      />
                      <b>Twitter</b>
                    </TwitterShareButton>
                    <br />
                  </DropdownItem>
                  <DropdownItem style={{ border: "none" }}>
                    <WhatsappShareButton
                      style={{ marginRight: 50 }}
                      className="sosmed text-left ml-1"
                      url={
                        process.env.REACT_APP_DOMAIN +
                        "/share-assessment-papikostick/" +
                        token
                      }
                      separator={
                        "Inilah hasil tes gaya kerjaku. Cek milikmu disini!"
                      }
                    >
                      <WhatsappIcon
                        size={32}
                        round={true}
                        style={{ marginRight: "1rem" }}
                      />
                      <b>WhatsApp</b>
                    </WhatsappShareButton>
                  </DropdownItem>
                  <DropdownItem style={{ border: "none" }} onClick={diKlik}>
                    <CopyToClipboard
                      className="text-left ml-2"
                      text={
                        process.env.REACT_APP_DOMAIN +
                        "/share-assessment-papikostick/" +
                        token
                      }
                    >
                      <div>
                        <img
                          src={require("../../../../assets/img/copy.png")}
                          alt=""
                          style={{ width: "25px" }}
                        />
                        <b style={{ cursor: "pointer", marginLeft: "20px" }}>
                          Copy Link
                        </b>
                      </div>
                    </CopyToClipboard>
                  </DropdownItem>
                </DropdownMenu>
              </ButtonDropdown>
            </Col>
          </Row>
        </div>
        {/* <div
                                className="mx-auto grafik-papikostick-user mt-4"
                                style={{
                                  backgroundSize: "auto 85%",
                                  marginBottom: 110,
                                  }}
                                  >
                                    <Radar
                                      data={data}
                                      options={options}
                                      width={100}
                                      height={75}
                                      ref={chartRef}
                                    />
                                  </div> */}

        <div
          className={
            langUtils.getLanguage() === "EN"
              ? "mx-auto  grafik-papikostick-user-en"
              : "mx-auto  grafik-papikostick-user"
          }
          style={{
            backgroundSize: "auto 95%",
            marginBottom: 110,
          }}
        >
          <Radar
            data={data}
            options={options}
            width={100}
            height={75}
            ref={chartRef}
          />
        </div>
        <div className="table">
          {result.result && <TablePapikostick result={result} />}
        </div>
      </Container>
    </>
  );
}

function titleColor(resultTitle) {
  switch (resultTitle) {
    case "Followership":
      return "#FFA39E";
    case "Work Direction":
      return "#D3ADF7";
    case "Leadership":
      return "#ADC6FF";
    case "Activity":
      return "#91D5FF";
    case "Social Nature":
      return "#B7EB8F";
    case "Work Style":
      return "#EAFF8F";
    case "Temperament":
      return "#FFD591";
    default:
      return "#FFFFFF";
  }
}

function TablePapikostick({ result }) {
  const template = {
    Followership: ["F", "W"],
    "Work Direction": ["N", "G", "A"],
    Leadership: ["L", "P", "I"],
    Activity: ["T", "V"],
    "Social Nature": ["X", "S", "B", "O"],
    "Work Style": ["C", "D", "R"],
    Temperament: ["Z", "E", "K"],
  };
  const groupingDesc = {
    F: t("Assessment.Keb Membantu Atasan"),
    W: t("Assessment.Keb Mengikuti Aturan dan Pengawasan"),
    N: t("Assessment.Keb dalam Menyelesaikan Tugas (Kemandirian)"),
    G: t("Assessment.Peran Pekerja Keras"),
    A: t("Assessment.Keb dalam Berprestasi"),
    L: t("Assessment.Peran Kepemimpinan"),
    P: t("Assessment.Keb Mengatur Orang Lain"),
    I: t("Assessment.Peran Dalam Membuat Keputusan"),
    T: t("Assessment.Peran Dalam Kesibukan Kerja"),
    V: t("Assessment.Peran Dalam Semangat Kerja"),
    X: t("Assessment.Keb untuk Diperhatikan"),
    S: t("Assessment.Peran Dalam Hubungan Sosial"),
    B: t("Assessment.Keb Diterima dalam Kelompok"),
    O: t("Assessment.Keb Kedekatan dan Kasih Sayang"),
    C: t("Assessment.Peran Dalam Mengatur"),
    D: t("Assessment.Peran Bekerja dengan Hal-hal Rinci"),
    R: t("Assessment.Peran Penalaran Teoritis"),
    Z: t("Assessment.Keb untuk Berubah"),
    E: t("Assessment.Peran Dalam Pengendalian Emosi"),
    K: t("Assessment.Keb untuk Agresif"),
  };

  const group =
    result.result &&
    Object.keys(result.result).map((category) => {
      const item =
        result.result &&
        template[category].map((code, idx) => ({
          code: code,
          scores: result.scores[code],
          description: result.result[category][idx],
        }));

      return {
        kategory: category,
        items: item,
      };
    });
  const [collapse, setCollapse] = useState(0);
  const toggle = (value) => {
    setCollapse(collapse === Number(value) ? 0 : Number(value));
  };
  return (
    <Fragment>
      {group &&
        group.map((objKategori, index) => {
          return (
            <div
              lg="12"
              className="text-justify"
              style={{ marginTop: "-20px" }}
            >
              <Button
                key={index}
                block
                rowSpan={objKategori.items.length}
                onClick={() => toggle(index)}
                data-event={index}
                style={{
                  backgroundColor: titleColor(objKategori.kategory),
                  color: "#FFFFFF",
                  paddingTop: 20,
                  paddingBottom: 20,
                }}
              >
                <Row>
                  <Col>
                    <h5
                      style={{ margin: 0, color: "#000" }}
                      className="text-left"
                    >
                      {objKategori.kategory}
                    </h5>
                  </Col>
                  <Col className="text-right">
                    <i
                      className="fa fa-angle-down"
                      style={{
                        fontSize: 20,
                        fontWeight: "bold",
                        marginRight: 30,
                      }}
                    ></i>
                  </Col>
                </Row>
              </Button>
              <Card>
                {objKategori &&
                  objKategori.items.map((objItem, idx) => {
                    return (
                      <Collapse isOpen={collapse === index}>
                        <CardBody className="navtext text-center">
                          <Star value={(objItem.scores + 1) / 2} />
                          <br />
                          <strong>{groupingDesc[objItem.code]}</strong>
                          <br />
                          {objItem.description}
                        </CardBody>
                      </Collapse>
                    );
                  })}
              </Card>
            </div>
          );
        })}
    </Fragment>
  );
}

export default translate(PapikostickResult);
