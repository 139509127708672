import React, { useState, useEffect } from "react";
import LoadingAnimation from "../../../../../../components/atoms/LoadingAnimation";
import request from "../../../../../../utils/Request";
import { Alert } from "reactstrap";
import ResultFingerprint from "../../../../../Templates/ResultAssessment/ResultAssessmentFingerprint";
import { translate } from "react-switch-lang";
function Fingerprint(props) {
  const { t } = props;
  const [loading, setLoading] = useState(true);
  const [hasDone, setHasDone] = useState(false);
  const [result, setResult] = useState([]);
  useEffect(() => {
    request
      .get(`/users/validate`)
      .then((res) => {
        request
          .get("/assessment/test/fingerprint/validate")
          .then((res) => {
            if (res.data?.type) {
              const type = res.data.type_done;
              const exist = res.data.type;

              if (type === "HAS_DONE") {
                request
                  .get("/assessment/test/result_fingerprint")
                  .then((res) => {
                    setResult(res.data);
                    setHasDone(true);
                    setLoading(false);
                  })
                  .catch((err) => {
                    setLoading(false);
                    setHasDone(false);
                  });
              } else if (type === "NOT_DONE") {
                if (exist === "DATA_EXIST") {
                  setLoading(false);
                  setHasDone(false);
                } else {
                  setLoading(false);
                  setHasDone(false);
                }
              }
            }
          })
          .catch((err) => {});
      })
      .catch((err) => {
        if (err.response?.status === 422) {
          setLoading(false);
        } else {
          setLoading(false);
          return Promise.reject(err);
        }
      });
  }, []);

  return (
    <div className="pb-5 animated fadeIn">
      {loading ? (
        <LoadingAnimation />
      ) : hasDone ? (
        <ResultFingerprint result={result} back={false} />
      ) : (
        <Alert color="danger" className="text-center mt-3 mb-4">
          <h6 className="content-title mb-2">
            {t("Assessment.sorryNoAssessmentBiometric")}
          </h6>
        </Alert>
      )}
    </div>
  );
}

export default translate(Fingerprint);
