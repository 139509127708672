import React from "react";
import ReactPlayer from "react-player";
import { ModalHeader, Modal, ModalBody } from "reactstrap";
import { t } from "react-switch-lang";

const ModalVideo = (props) => {
  const { className } = props;
  console.log(props.url);
  return (
    <div>
      <Modal
        isOpen={props.isModalReplay}
        toggle={props.toggleReplay}
        className={className}
      >
        <ModalHeader toggle={props.toggleReplay}>
          {t("Interview.recordedVideo")}
        </ModalHeader>
        <ModalBody>
          <ReactPlayer
            className="react-player"
            url={props.url}
            width="100%"
            height="100%"
            playing={true}
            controls={true}
            loop={false}
          />
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ModalVideo;
