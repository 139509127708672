import React, { Fragment, useEffect, useState } from "react";
import LoadingAnimation from "../../../../../components/atoms/LoadingAnimation";
import request from "../../../../../utils/Request";
import UserGuide from "../../../../Templates/AgilityTest/UserGuide";
import { translate, t } from "react-switch-lang";
import AffirmationModal from "../../../../../components/atoms/AffirmationModal";
import AgilityResult from "../../../../Templates/ResultAssessment/ResultAgility";
import NoComplete from "../NoCompleteProfile";
import NotFound from "../../../NotFound";
import requestGeneral from "../../../../../utils/RequestGeneral";
function Agility() {
  const [loading, setLoading] = useState(true);
  const [isComplete, setComplete] = useState(false);
  const [error, setError] = useState(false);
  const [noComplete, setNoComplete] = useState(false);
  const [message, setMessage] = useState(null);
  const [redirect, setRedirect] = useState(false);
  useEffect(() => {
    if (localStorage.getItem("referal")) {
      requestGeneral
        .get("internal/recruitment/internal/" + localStorage.getItem("referal"))
        .then((res) => {
          if (
            res?.data?.data?.requirements?.filter((item) =>
              item?.toLowerCase().includes("agility")
            ).length === 0
          ) {
            setRedirect(true);
            setLoading(false);
          } else if (
            res?.data?.data?.requirements?.filter((item) =>
              item?.toLowerCase().includes("profile")
            ).length > 0
          ) {
            validate();
          } else {
            getAgility();
          }
        })
        .catch((err) => {
          getAgility();
        });
    } else {
      getAgility();
    } // eslint-disable-next-line
  }, []);
  function getAgility() {
    request
      .get("/assessment/test/agility/validate")
      .then((res) => {
        if (res.data?.success) {
          setComplete(true);
          setLoading(false);
          setMessage(res.data.message);
        } else {
          setComplete(false);
          setLoading(false);
        }
      })
      .catch((err) => {
        if (err.response.data.success === true) {
          setComplete(true);
          setLoading(false);
          setMessage(err.response.data.message);
        } else {
          setError(true);
          setLoading(false);
        }
      });
  }
  function validate() {
    requestGeneral
      .get(`/v2/users/validate-standart`)
      .then((res) => {
        getAgility();
      })
      .catch((err) => {
        if (err.response?.status === 422) {
          setNoComplete(true);
          setLoading(false);
        } else {
          setError(true);
          setLoading(false);
          return Promise.reject(err);
        }
      });
  }
  function isError() {
    if (error) {
      return (
        <AffirmationModal
          isOpen={error}
          title={"Error !"}
          desc={t("Agility.errorConnection")}
          titleButton={t("General.okey")}
          toggleOpen={() => {
            setError(true);
          }}
          confirmation={() => {
            window.location.reload();
            setError(false);
          }}
        />
      );
    }
  }
  return (
    <Fragment>
      {isError()}
      {/* {modalAttentionExistData()} */}
      {loading ? (
        <LoadingAnimation />
      ) : redirect ? (
        <NotFound />
      ) : error ? null : noComplete ? (
        <NoComplete />
      ) : isComplete ? (
        <AgilityResult back={true} message={message} />
      ) : (
        <UserGuide setComplete={setComplete} />
      )}
    </Fragment>
  );
}

export default translate(Agility);
