import React, { Fragment, useEffect, useState } from "react";
import DefaultHeader from "../DefaultHeaderHome";
import { AppHeader } from "@coreui/react";
import { Route } from "react-router";
import request from "../../../../../utils/Request";
import { connect } from "react-redux";
import { Row, Button } from "reactstrap";
import { translate } from "react-switch-lang";
// import ShortGuideUser from "../../../../Templates/ShortGuideUser";
import WhatsAppButton from "../../../../Templates/LandingPage/WhatsAppButton";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import Tour from "reactour";

const HomeLayout = (props) => {
  const toUp = window.scrollTo(0, 0);
  const disableBody = (target) => disableBodyScroll(target);
  const enableBody = (target) => enableBodyScroll(target);
  const accentColor = "#93aad6";
  const [isTourOpen, setIsTourOpen] = useState(false);
  const [isModal, setIsModal] = useState(false);
  const { t } = props;
  useEffect(() => {
    if (props.user) {
      if (props.user.guideHome === 0) {
        setIsModal(true);
      }
    }
  }, [props.user]);
  function disableUserGuide() {
    request
      .put("/users/disable_user_guide_home")
      .then((res) => {
        props.setUser({
          ...props.user,
          guideHome: 1,
        });
      })
      .catch((err) => {
        props.setUser({
          ...props.user,
          guideHome: 1,
        });
      });
  }
  const steps = [
    {
      selector: '[data-tut="logo-user"]',
      content: ({ goTo, inDOM }) => (
        <div>
          <p>{t("UserGuide.homeChangePassword")}</p>
          <div className="col-12 text-center">
            <Row>
              <div className="col-12 text-right p-0">
                <Button
                  className="mt-2"
                  type="submit"
                  color="netis-color"
                  onClick={() => goTo(1)}
                >
                  {t("UserGuide.next")}{" "}
                  <i className="fa fa-arrow-right ml-2"></i>
                </Button>
              </div>
            </Row>
          </div>
        </div>
      ),
    },
    {
      selector: ".all-menu",
      content: ({ goTo, inDOM }) => (
        <div>
          <p>{t("UserGuide.homeMenu")}</p>
          <div className="col-12 text-center">
            <Row>
              <div className="col-6 text-center p-0">
                <Button
                  className="mt-2"
                  type="submit"
                  color="netis-color"
                  onClick={() => goTo(0)}
                >
                  <i className="fa fa-arrow-left mr-2"></i>
                  {t("UserGuide.previous")}
                </Button>
              </div>
              <div className="col-6 text-center p-0">
                <Button
                  className="mt-2"
                  type="submit"
                  color="netis-color"
                  onClick={() => goTo(2)}
                >
                  {t("UserGuide.next")}{" "}
                  <i className="fa fa-arrow-right ml-2"></i>
                </Button>
              </div>
            </Row>
          </div>
        </div>
      ),
    },
    {
      selector: ".step1-home",
      content: ({ goTo, inDOM }) => (
        <div>
          <p>{t("UserGuide.homeMenuProfile")}</p>
          <div className="col-12 text-center">
            <Row>
              <div className="col-6 text-center p-0">
                <Button
                  className="mt-2"
                  type="submit"
                  color="netis-color"
                  onClick={() => goTo(1)}
                >
                  <i className="fa fa-arrow-left mr-2"></i>
                  {t("UserGuide.previous")}
                </Button>
              </div>
              <div className="col-6 text-center p-0">
                <Button
                  className="mt-2"
                  type="submit"
                  color="netis-color"
                  onClick={() => goTo(3)}
                >
                  {t("UserGuide.next")}{" "}
                  <i className="fa fa-arrow-right ml-2"></i>
                </Button>
              </div>
            </Row>
          </div>
        </div>
      ),
    },
    {
      selector: ".step2-home",
      content: ({ goTo, inDOM }) => (
        <div>
          <p>{t("UserGuide.homeMenuAssessment")}</p>
          <div className="col-12 text-center">
            <Row>
              <div className="col-6 text-center p-0">
                <Button
                  className="mt-2"
                  type="submit"
                  color="netis-color"
                  onClick={() => goTo(2)}
                >
                  <i className="fa fa-arrow-left mr-2"></i>
                  {t("UserGuide.previous")}
                </Button>
              </div>
              <div className="col-6 text-center p-0">
                <Button
                  className="mt-2"
                  type="submit"
                  color="netis-color"
                  onClick={() => goTo(4)}
                >
                  {t("UserGuide.next")}{" "}
                  <i className="fa fa-arrow-right ml-2"></i>
                </Button>
              </div>
            </Row>
          </div>
        </div>
      ),
    },
    {
      selector: ".step3-home",
      content: ({ goTo, inDOM }) => (
        <div>
          <p>{t("UserGuide.homeMenuApplication")}</p>
          <div className="col-12 text-center">
            <Row>
              <div className="col-6 text-center p-0">
                <Button
                  className="mt-2"
                  type="submit"
                  color="netis-color"
                  onClick={() => goTo(3)}
                >
                  <i className="fa fa-arrow-left mr-2"></i>
                  {t("UserGuide.previous")}
                </Button>
              </div>
              <div className="col-6 text-center p-0">
                <Button
                  className="mt-2"
                  type="submit"
                  color="netis-color"
                  onClick={() => goTo(5)}
                >
                  {t("UserGuide.next")}{" "}
                  <i className="fa fa-arrow-right ml-2"></i>
                </Button>
              </div>
            </Row>
          </div>
        </div>
      ),
    },
    {
      selector: ".step4-home",
      content: ({ goTo, inDOM }) => (
        <div>
          <p>{t("UserGuide.homeMenuMyVideo")}</p>
          <div className="col-12 text-center">
            <Row>
              <div className="col-6 text-center p-0">
                <Button
                  className="mt-2"
                  type="submit"
                  color="netis-color"
                  onClick={() => goTo(4)}
                >
                  <i className="fa fa-arrow-left mr-2"></i>
                  {t("UserGuide.previous")}
                </Button>
              </div>
              <div className="col-6 text-center p-0">
                <Button
                  className="mt-2"
                  type="submit"
                  color="netis-color"
                  onClick={() => goTo(6)}
                >
                  {t("UserGuide.next")}{" "}
                  <i className="fa fa-arrow-right ml-2"></i>
                </Button>
              </div>
            </Row>
          </div>
        </div>
      ),
    },
    {
      selector: ".step-job-menu",
      content: ({ goTo, inDOM }) => (
        <div>
          <p>{t("UserGuide.homeSeeApplication")}</p>
          <div className="col-12 text-center">
            <Row>
              <div className="col-6 text-center p-0">
                <Button
                  className="mt-2"
                  type="submit"
                  color="netis-color"
                  onClick={() => {
                    goTo(5);
                  }}
                >
                  <i className="fa fa-arrow-left mr-2"></i>
                  {t("UserGuide.previous")}
                </Button>
              </div>
              <div className="col-6 text-center p-0">
                <Button
                  className="mt-2"
                  type="submit"
                  color="netis-color"
                  onClick={() => goTo(7)}
                >
                  {t("UserGuide.next")}{" "}
                  <i className="fa fa-arrow-right ml-2"></i>
                </Button>
              </div>
            </Row>
          </div>
        </div>
      ),
    },
    {
      selector: ".step-next-job",
      content: ({ goTo, inDOM }) => (
        <div>
          <p>
            {t("UserGuide.homeSeeInformation")} <b> {t("UserGuide.more")} </b>{" "}
            {t("UserGuide.button")}.
          </p>
          <div className="col-12 text-center">
            <Row>
              <div className="col-6 text-center p-0">
                <Button
                  className="mt-2"
                  type="submit"
                  color="netis-color"
                  onClick={() => goTo(6)}
                >
                  <i className="fa fa-arrow-left mr-2"></i>
                  {t("UserGuide.previous")}
                </Button>
              </div>
              <div className="col-6 text-center p-0">
                <Button
                  className="mt-2"
                  type="submit"
                  color="netis-color"
                  onClick={() => {
                    disableUserGuide();
                    enableBody();
                    setIsTourOpen(false);
                  }}
                >
                  {t("UserGuide.okUnderstand")}
                </Button>
              </div>
            </Row>
          </div>
        </div>
      ),
    },
    // ...
  ];
  const stepsFirstModal = [
    {
      content: () => (
        <div>
          <h4
            className="title-upgrade text-center"
            style={{ color: "#93aad6" }}
          >
            {t("UserGuide.welcome")}
          </h4>
          {/* <CardImg
            style={{ width: "280px" }}
            src={require("../../../../../assets/img/guide/Asset_1.png")}
            alt="Card image cap"
          /> */}
          <p className="text-justify">{t("UserGuide.welcome2")}</p>
          <div className="col-12 text-center">
            <Row>
              <div className="col-6 text-center p-0">
                <Button
                  className="mt-2"
                  type="submit"
                  color="netis-danger"
                  onClick={() => {
                    disableUserGuide();
                    setIsModal(false);
                  }}
                >
                  {t("UserGuide.buttonNo")}
                </Button>
              </div>
              <div className="col-6 text-center p-0">
                <Button
                  className="mt-2"
                  type="submit"
                  color="netis-color"
                  onClick={() => {
                    setIsTourOpen(true);
                    setIsModal(false);
                  }}
                >
                  {t("UserGuide.buttonYes")}
                </Button>
              </div>
            </Row>
          </div>
        </div>
      ),
    },
  ];
  return (
    <Fragment>
      {toUp}
      <div className="app" style={{ background: "#fff" }}>
        {!localStorage.getItem("referal") ? (
          <>
            <Tour
              showCloseButton={false}
              steps={stepsFirstModal}
              accentColor={accentColor}
              showNumber={false}
              isOpen={isModal}
              disableDotsNavigation={true}
              rounded={5}
              showNavigation={false}
              showButtons={false}
              disableFocusLock={false}
              onBeforeClose={enableBody}
              className="p-4"
              onRequestClose={() => {
                disableUserGuide();
                setIsModal(false);
              }}
            />
            <Tour
              steps={steps}
              accentColor={accentColor}
              showButtons={false}
              rounded={5}
              isOpen={isTourOpen}
              disableFocusLock={false}
              onAfterOpen={disableBody}
              onBeforeClose={enableBody}
              onRequestClose={() => {
                disableUserGuide();
                setIsTourOpen(false);
              }}
            />
          </>
        ) : null}
        <AppHeader fixed>
          <DefaultHeader isTourOpen={isTourOpen} />
        </AppHeader>
        <div className="app-body-breadcrumb" style={{ paddingTop: 70 }}>
          <main style={{ width: "100%" }}>
            <Route {...props} />
          </main>
        </div>
        {/* <ShortGuideUser /> */}
        <WhatsAppButton />
      </div>
    </Fragment>
  );
};
const mapsToDispatch = (dispatch) => {
  return {
    setUser: (state) =>
      dispatch({
        type: "SET_USER",
        payload: state,
      }),
  };
};
export default connect(
  ({ user }) => ({ user }),
  mapsToDispatch
)(translate(HomeLayout));
