import React, { useState, useRef } from "react";
import { translate, t } from "react-switch-lang";
import Question from "../../../../Templates/QuestionsDisc";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Progress,
  Container,
} from "reactstrap";
import { useEffect } from "react";
import request from "../../../../../utils/Request";
import LoadingAnimation from "../../../../../components/atoms/LoadingAnimation";
import DiscResult from "../../../../Templates/ResultAssessment/ResultAssessmentDisc";
import requestGeneral from "../../../../../utils/RequestGeneral";
import { Prompt } from "react-router-dom";
import NoComplete from "../NoCompleteProfile";
import NotFound from "../../../NotFound";
import Spm from "../../../../Templates/Spm";
function arrayChunk(array, size = 10) {
  return Array(Math.ceil(array.length / size))
    .fill()
    .map((_, index) => index * size)
    .map((begin) => array.slice(begin, begin + size));
}

function Disc(props) {
  // const {isAdminPanel} = props;
  const [source, setSource] = useState([]);
  const [loading, setLoading] = useState(true);

  const [answers, setAnswers] = useState({});

  const [page, setPage] = useState(0);
  const [analyzing, setAnalyzing] = useState(false);
  const [result, setResult] = useState(null);
  const [hint, setHint] = useState(false);
  const [red, setRed] = useState(false);
  const refs = useRef([React.createRef(), React.createRef()]);
  const [isNotComplete, setNotComplete] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [spm, setSpm] = useState(false);
  const [spmTes, setSpmTes] = useState(false);
  const [resultPage, setResultPage] = useState(false);
  const [req, setReq] = useState([]);

  useEffect(() => {
    if (localStorage.getItem("referal")) {
      requestGeneral
        .get("internal/recruitment/internal/" + localStorage.getItem("referal"))
        .then((res) => {
          setReq(res?.data?.data?.requirements);
          if (
            res?.data?.data?.requirements?.filter((item) =>
              item?.toLowerCase().includes("spm")
            ).length > 0
          ) {
            setSpm(true);
          } else {
            if (
              res?.data?.data?.requirements?.filter((item) =>
                item?.toLowerCase().includes("disc")
              ).length === 0
            ) {
              setRedirect(true);
              setLoading(false);
            } else if (
              res?.data?.data?.requirements?.filter((item) =>
                item?.toLowerCase().includes("profile")
              ).length > 0
            ) {
              validate();
            } else {
              getDisc();
            }
          }
        })
        .catch((err) => {
          getDisc();
        });
    } else {
      getDisc();
    }
  }, []); //eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (spm === true) {
      if (
        req.filter((item) => item?.toLowerCase().includes("disc")).length === 0
      ) {
        setRedirect(true);
        setLoading(false);
      } else if (
        req.filter((item) => item?.toLowerCase().includes("profile")).length > 0
      ) {
        validate();
      } else {
        getDisc();
      }
    }
  }, [spm]); //eslint-disable-line react-hooks/exhaustive-deps

  function getDisc() {
    request
      .get("/assessment/test/disc")
      .then((res) => {
        if (res.data?.data) {
          const source = res.data.data;
          setSource(source);
          setLoading(false);
          setHint(true);
        }
      })
      .catch((err) => {
        if (
          err.response?.status === 422 &&
          err.response.data.data.error_type === "HAS_DONE_TEST"
        ) {
          const resultExpiredDateTime = new Date(
            err.response.data.data.result_expired_at
          );
          request.get("/assessment/test/disc/result").then((res) => {
            const resultResponse = res.data.data;
            resultResponse["expired_at"] = resultExpiredDateTime;
            resultResponse["message"] = res.data.message;
            setResult(resultResponse);
            if (localStorage.getItem("referal")) {
              console.log(spm);
              if (spm) {
                getSpm();
              } else {
                setResultPage(true);
                setLoading(false);
              }
            } else {
              getSpm();
            }
          });
        } else {
          setLoading(false);
          return Promise.reject(err);
        }
      });
  }
  function validate() {
    requestGeneral
      .get(`/v2/users/validate-standart`)
      .then((res) => {
        getDisc();
      })
      .catch((err) => {
        if (err.response?.status === 422) {
          setNotComplete(true);
          setLoading(false);
        } else {
          setLoading(false);
          return Promise.reject(err);
        }
      });
  }
  const groupedQuestions = arrayChunk(source, 4);
  // useEffect(() => {
  //   console.log(groupedQuestions);
  //   if (groupedQuestions.length > 0) {
  //     groupedQuestions[page].map((question, idx) => (refs.current[idx] = idx));
  //   }
  //   // eslint-disable-next-line
  // }, [groupedQuestions]);

  if (loading || analyzing) {
    return <LoadingAnimation />;
  }
  if (redirect) {
    return <NotFound />;
  }
  if (isNotComplete) {
    return <NoComplete />;
  }
  if (resultPage) {
    return <DiscResult result={result} back={true} />;
  }

  if (spmTes) {
    return <Spm />;
  }
  function onAnswered(code, answer) {
    setAnswers({ ...answers, [code]: answer });
    setRed(null);
    // console.log(groupedQuestions[page])
    // console.log(answers)
    // {q1: {most: 'a1'}}
  }

  const pagesLength = groupedQuestions.length;
  // const keyAnswers = Object.keys(answers);
  const canGoToNext =
    // groupedQuestions[page].map(q => q.code).every(code => Object.keys(answers).indexOf(code) !== -1)
    groupedQuestions[page].length === 4 &&
    groupedQuestions[page].every((qcode) => answers[qcode.code]?.most) &&
    groupedQuestions[page].every((qcode) => answers[qcode.code]?.least);

  function nextPage() {
    if (page < pagesLength && canGoToNext) {
      setPage(page + 1);
      window.scroll({ top: 0, behavior: "smooth" });
    } else {
      for (var i = 0; i < groupedQuestions[page].length; i++) {
        if (answers[groupedQuestions[page][i].code]) {
          if (answers[groupedQuestions[page][i].code].most) {
            if (!answers[groupedQuestions[page][i].code].least) {
              setRed(i);
              refs.current[i].scrollIntoView();
              break;
            }
          } else {
            setRed(i);
            refs.current[i].scrollIntoView();
            break;
          }
        } else {
          setRed(i);
          refs.current[i].scrollIntoView();
          break;
        }
      }
    }
    //window.scroll({ top: "#guide1", behavior: "smooth" });
  }

  function nextPageSubmit() {
    if (page < pagesLength && canGoToNext) {
      submitAnswers();
    } else {
      for (var i = 0; i < groupedQuestions[page].length; i++) {
        if (answers[groupedQuestions[page][i].code]) {
          if (answers[groupedQuestions[page][i].code].most) {
            if (!answers[groupedQuestions[page][i].code].least) {
              setRed(i);
              refs.current[i].scrollIntoView();
              break;
            }
          } else {
            setRed(i);
            refs.current[i].scrollIntoView();
            break;
          }
        } else {
          setRed(i);
          refs.current[i].scrollIntoView();
          break;
        }
      }
    }
    //window.scroll({ top: "#guide1", behavior: "smooth" });
  }

  function prevPage() {
    setRed(null);
    if (page > 0) {
      setPage(page - 1);
    }
    window.scroll({ top: 0, behavior: "smooth" });
  }
  function submitAnswers() {
    setAnalyzing(true);

    let id = null;
    let idx = 0;
    groupedQuestions[page].some(function (qcode) {
      if (answers[qcode.code]) {
        if (answers[qcode.code].most) {
          if (!answers[qcode.code].least) {
            return (id = idx);
          }
        } else {
          return (id = idx);
        }
      } else {
        return (id = idx);
      }
      idx++;
      return false;
    });
    if (id !== null) {
      refs.current[id].scrollIntoView();
    } else {
      request.post("/assessment/test/disc", { answers }).then((res) => {
        setResult(res.data.data);
        if (localStorage.getItem("referal")) {
          if (spm) {
            getSpm();
          } else {
            setAnalyzing(false);
            setResultPage(true);
          }
        } else {
          getSpm();
        }
      });
    }
  }
  const numAnswered = Object.keys(answers).length;
  const numQuestions = source.length;

  function getSpm() {
    request
      .get("/assessment/test/spm/validate")
      .then((res) => {
        if (res.data?.type) {
          const type = res.data.type_done;
          if (type === "HAS_DONE") {
            setResultPage(true);
            setAnalyzing(false);
            setLoading(false);
          } else {
            setSpmTes(true);
            setAnalyzing(false);
            setLoading(false);
          }
        }
      })
      .catch((err) => {
        setAnalyzing(false);
        setLoading(false);
        return Promise.reject(err);
      });
  }
  return (
    <div className="p-5">
      <Container>
        <div className="pb-5">
          <Prompt
            when={numAnswered > 0 && numAnswered !== numQuestions}
            message={(location) => t("General.sureWantToLeave")}
          />
          <div className="d-flex justify-content-between">
            <h3 className="h1">{t("Assessment.personalityTitle") + " I"}</h3>
            <Button
              onClick={() => setHint(true)}
              className="text-nowrap"
              style={{ backgroundColor: "transparent", border: "transparent" }}
            >
              <i className="fa fa-lg fa-question-circle text-primary" />
              &nbsp;
              <small>
                <b>{t("General.hint")}</b>
              </small>
            </Button>
          </div>
          <hr />
          <p autoCapitalize="none">
            {t("Assessment.subTitleDisc1")}{" "}
            <b> {t("Assessment.subTitleDisc2")}</b>&nbsp;{" "}
            {t("Assessment.subTitleDisc3")}{" "}
            <b> {t("Assessment.subTitleDisc4")}</b>
          </p>

          <Progress
            className="my-4"
            animated
            color={
              page === pagesLength - 1 && canGoToNext ? "success" : undefined
            }
            value={
              (Object.values(answers).length / groupedQuestions.length / 6) *
              150
            }
          ></Progress>

          {groupedQuestions[page].map((question, idx) => (
            <div className="navtext" key={idx}>
              <Question
                {...question}
                page={page}
                idx={idx}
                red={red}
                key={question.code}
                onAnswered={onAnswered}
                answer={answers[question.code]}
                refs={refs}
              />
            </div>
          ))}
          <div
            className="d-flex justify-content-around"
            style={{ width: "100%" }}
          >
            {page > 0 && (
              <Button
                style={{ width: "125px" }}
                className="navtext btn btn-lg btn-netis-color"
                onClick={prevPage}
              >
                <i className="fa fa-arrow-left mr-2"></i>
                {t("General.back")}
              </Button>
            )}
            {page < pagesLength - 1 && (
              <Button
                style={{ width: "125px" }}
                className="navtext btn btn-md btn-netis-color"
                onClick={nextPage}
              >
                {t("General.next")}
                <i className="fa fa-arrow-right ml-2"></i>
              </Button>
            )}
            {page === pagesLength - 1 && (
              <Button
                color="success"
                style={{ width: "125px" }}
                className="btn-two btn btn-lg btn-netis-success"
                onClick={nextPageSubmit}
              >
                <i className="fa fa-paper-plane mr-2"></i>
                {t("General.submit")}
              </Button>
            )}
            {/* {page === (pagesLength -1 ) && <Button className="btn btn-lg btn-success" onClick={submitAnswers} disabled={!canGoToNext}><i className="fa fa-paper-plane mr-2"></i>SUBMIT</Button>} */}
          </div>

          <Modal isOpen={hint} style={{ fontSize: "12pt" }}>
            <ModalHeader>{t("Assessment.guideDisc")}</ModalHeader>
            <ModalBody className="navtext">
              <p>{t("Assessment.titleGuideDisc")}</p>
              <ol>
                <li>
                  {t("Assessment.inTheCheckbox")} (&nbsp;
                  <i className="fa fa-check" style={{ color: "#32CD32" }} />
                  &nbsp;) {t("Assessment.inTheCheckbox2")}{" "}
                  <b>{t("Assessment.inTheCheckbox3")}</b>{" "}
                  {t("Assessment.inTheCheckbox4")}.
                </li>
                <br />
                <li>
                  {t("Assessment.inTheCrossColumn")} (&nbsp;
                  <i className="fa fa-times" style={{ color: "#FF0000" }} />
                  &nbsp;) {t("Assessment.inTheCheckbox2")}{" "}
                  <b>{t("Assessment.inTheCrossColumn2")}</b>{" "}
                  {t("Assessment.inTheCheckbox4")}.
                </li>
                <br />
                <li>
                  {t("Assessment.cannotChooseBoth")} (&nbsp;
                  <i className="fa fa-check" style={{ color: "#32CD32" }} />
                  &nbsp;) {t("Assessment.andCross")} (&nbsp;
                  <i className="fa fa-times" style={{ color: "#FF0000" }} />
                  &nbsp;).
                </li>
              </ol>
            </ModalBody>
            <ModalFooter>
              <Button
                className="navtext"
                color="netis-color"
                onClick={() => setHint(false)}
              >
                {t("Assessment.agree")}
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      </Container>
    </div>
  );
}

export default translate(Disc);
