import React, { useState, useEffect, useRef, Fragment } from "react";
import {
  Button,
  Card,
  CardBody,
  Row,
  Col,
  ListGroup,
  ListGroupItem,
  Container,
} from "reactstrap";
import { connect } from "react-redux";
import LoadingAnimation from "../../../../components/atoms/LoadingAnimation";
import request from "../../../../utils/Request";
import moment from "../../../../utils/Moment";
import { Link, Redirect } from "react-router-dom";
import FooterLandingPage from "../../../Templates/LandingPage/FooterLandingPage";
import notFound from "../../../../assets/img/ops_interview.png";
import notFoundEn from "../../../../assets/img/ops_interview_en.png";
import AffirmationModal from "../../../../components/atoms/AffirmationModal";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import requestGeneral from "../../../../utils/RequestGeneral";
import langUtils from "../../../../utils/SettingLanguage/index";
import Tour from "reactour";
import { translate, t } from "react-switch-lang";

const InterviewOnline = (props) => {
  moment.locale(langUtils.getLanguage());
  const vidRef = useRef(null);
  const vidRef2 = useRef(null);
  const [loading, setLoading] = useState(true);
  const [showButton, setShowButton] = useState(true);
  const [showButton2, setShowButton2] = useState(true);
  const [createdAt, setCreatedAt] = useState(null);
  const [notQuota, setNotQuota] = useState(true);
  const [noData, setNoData] = useState(false);
  const [activeSources, setActiveSources] = useState([]);
  const [activeVideo, setActiveVideo] = useState(null);
  const [activeWidya, setActiveWidya] = useState(null);
  const [activeTitle, setActiveTitle] = useState(null);
  const [isNotComplete, setNotComplete] = useState(false);
  const [validate, setValidate] = useState(null);
  const disableBody = (target) => disableBodyScroll(target);
  const enableBody = (target) => enableBodyScroll(target);
  const accentColor = "#93aad6";
  const [isTourOpen, setIsTourOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [redirect, setRedirect] = useState(false);
  const handleOnClick = (idx) => {
    setActiveIndex(idx);
  };

  useEffect(() => {
    if (localStorage.getItem("referal")) {
      requestGeneral
        .get("internal/recruitment/internal/" + localStorage.getItem("referal"))
        .then((res) => {
          if (
            res?.data?.data?.requirements?.filter((item) =>
              item?.toLowerCase().includes("video")
            ).length === 0
          ) {
            setRedirect("/home");
            setLoading(false);
          } else if (
            res?.data?.data?.requirements?.filter((item) =>
              item?.toLowerCase().includes("profile")
            ).length > 0
          ) {
            validate_profile();
          } else {
            video();
          }
        })
        .catch((err) => {
          video();
        });
    } else {
      video();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  function validate_profile() {
    requestGeneral
      .get(`/v2/users/validate-standart`)
      .then((res) => {
        video();
      })
      .catch((err) => {
        if (err.response?.status === 422) {
          setNotComplete(true);
          setValidate("PROFILE_NOT_COMPLETE");
          setLoading(false);
        } else {
          setLoading(false);
          return Promise.reject(err);
        }
      });
  }
  function video() {
    request
      .get("/assessment_video/result_last_video")
      .then((res) => {
        const response = res.data.data;
        if (res.data.success === true) {
          setActiveVideo(response[0].raw);
          setActiveWidya(response[0].video);
          setActiveTitle(response[0].title);
          setActiveSources(response);
          setCreatedAt(res.data.created_at);
        }
        request
          .get("/assessment_video/validate_video")
          .then((res) => {
            const response = res.data.quota;
            if (response === 0) {
              setNotQuota(true);
              setLoading(false);
            } else if (response >= 3) {
              setNotQuota(false);
              setNoData(true);
              setLoading(false);
            } else {
              setNotQuota(false);
              setLoading(false);
            }
          })
          .catch((err) => {
            setNotQuota(true);
            setLoading(false);
          });
      })
      .catch((err) => {
        setLoading(false);
      });
  }

  useEffect(() => {
    if (props.user) {
      if (props.user.guideInterview === 0) {
        if (!isNotComplete) {
          setIsTourOpen(true);
        }
      }
    }
  }, [props.user]); // eslint-disable-line react-hooks/exhaustive-deps
  function changeVideo(video) {
    setActiveWidya(video.video);
    setActiveTitle(video.title);
    setActiveVideo(video.raw);
  }

  const playVid = () => {
    vidRef2.current.pause();
    vidRef.current.play();
    setShowButton(false);
  };

  const playVid2 = () => {
    vidRef.current.pause();
    vidRef2.current.play();
    setShowButton2(false);
  };

  const pauseVid2 = () => {
    vidRef2.current.pause();
    setShowButton2(true);
  };

  function popUpNotCompleteAssessmentAndProfile() {
    if (isNotComplete) {
      return (
        <AffirmationModal
          isOpen={isNotComplete}
          title={t("General.sorry") + ", " + t("Interview.cannotCreateVideo")}
          desc={
            validate === "PROFILE_NOT_COMPLETE"
              ? t("Interview.mustCompleteProfile")
              : t("Interview.mustCompleteAssessment")
          }
          titleButton={
            validate === "PROFILE_NOT_COMPLETE"
              ? t("General.completeProfile") + " !"
              : t("General.completeAssessment") + " !"
          }
          toggleOpen={() => {
            setNotComplete(true);
          }}
          confirmation={() => {
            setNotComplete(false);
            setRedirect(
              validate === "PROFILE_NOT_COMPLETE" ? "/profile" : "/assessment"
            );
          }}
        />
      );
    }
  }
  const steps = [
    {
      selector: ".step-interview-1",
      content: ({ goTo, inDOM }) => (
        <div>
          <p>{t("UserGuide.myVideoMenu")}</p>
          <div className="col-12 text-center">
            <Row>
              <div className="col-12 text-center p-0">
                <Button
                  className="mt-2"
                  type="submit"
                  color="netis-color"
                  onClick={() => goTo(1)}
                >
                  {t("UserGuide.next")}{" "}
                  <i className="fa fa-arrow-right ml-2"></i>
                </Button>
              </div>
            </Row>
          </div>
        </div>
      ),
    },
    {
      selector: ".step-interview-2",
      content: ({ goTo, inDOM }) => (
        <div>
          <p>{t("UserGuide.myVideoSeeVideoList")}</p>
          <div className="col-12 text-center">
            <Row>
              <div className="col-6 text-center p-0">
                <Button
                  className="mt-2"
                  type="submit"
                  color="netis-color"
                  onClick={() => goTo(0)}
                >
                  <i className="fa fa-arrow-left mr-2"></i>
                  {t("UserGuide.previous")}
                </Button>
              </div>
              <div className="col-6 text-center p-0">
                <Button
                  className="mt-2"
                  type="submit"
                  color="netis-color"
                  onClick={() => {
                    enableBody();
                    disableUserGuide();
                    setIsTourOpen(false);
                  }}
                >
                  {t("UserGuide.okUnderstand")}
                </Button>
              </div>
            </Row>
          </div>
        </div>
      ),
    },
  ];
  function disableUserGuide() {
    request
      .put("/users/disable_user_guide_interview")
      .then((res) => {
        props.setUser({
          ...props.user,
          guideInterview: 1,
        });
      })
      .catch((err) => {
        props.setUser({
          ...props.user,
          guideInterview: 1,
        });
      });
  }

  return redirect !== false ? (
    <Redirect to={redirect} />
  ) : (
    <Fragment>
      {loading ? (
        <div className="mb-5 mt-5 pt-5 pb-5">
          <LoadingAnimation />
        </div>
      ) : (
        <Container>
          {popUpNotCompleteAssessmentAndProfile()}
          {!localStorage.getItem("referal") ? (
            <Tour
              steps={steps}
              accentColor={accentColor}
              showButtons={false}
              rounded={5}
              isOpen={isTourOpen}
              disableFocusLock={false}
              onAfterOpen={disableBody}
              onBeforeClose={enableBody}
              onRequestClose={() => {
                disableUserGuide();
                setIsTourOpen(false);
              }}
            />
          ) : null}
          <Row className="mb-4 mt-4">
            <Col sm="4">
              <Link to="/home">
                <button className="navtext btn btn-netis-color">
                  <i className="fa fa-arrow-left mr-2"></i>
                  {t("General.back")}
                </button>
              </Link>
            </Col>
            <Col sm="4" className="text-center">
              <h3 className="content-title">{t("Interview.CVVideo")}</h3>
            </Col>
            <Col sm="4" className="text-right">
              {/* <Link to="/photo-dummy">
                    <button className="navtext btn btn-warning mr-2">
                      <i className="fa fa-tree mr-2"></i>Dummy
                    </button>
                  </Link> */}
              {!localStorage.getItem("referal") ? (
                props.user.guideInterview === 0 ? (
                  <button
                    className="navtext btn btn-success step-interview-1"
                    disabled={notQuota}
                  >
                    <i className="fa fa-video-camera mr-2"></i>
                    {t("General.create")} {t("Interview.CVVideo")}
                  </button>
                ) : (
                  <Link to="/assessment-video">
                    <button
                      className="navtext btn btn-success step-interview-1"
                      disabled={notQuota}
                    >
                      <i className="fa fa-video-camera mr-2"></i>
                      {t("General.create")} {t("Interview.CVVideo")}
                    </button>
                  </Link>
                )
              ) : (
                <Link to="/assessment-video">
                  <button
                    className="navtext btn btn-success step-interview-1"
                    disabled={notQuota}
                  >
                    <i className="fa fa-video-camera mr-2"></i>
                    {t("General.create")} {t("Interview.CVVideo")}
                  </button>
                </Link>
              )}
              <br />
              {notQuota ? <i>{t("Interview.noQuota")}</i> : null}
            </Col>
          </Row>
          <Card>
            <CardBody className="step-interview-2">
              {noData ? (
                <Row>
                  <Col sm="3"></Col>
                  <Col sm="6" className="text-center">
                    {langUtils.getLanguage() === "ID" ? (
                      <img
                        style={{
                          width: "100%",
                          marginBottom: "2rem",
                        }}
                        src={notFound}
                        alt="lowongan-belum-ada"
                      />
                    ) : (
                      <img
                        style={{
                          width: "100%",
                          marginBottom: "2rem",
                        }}
                        src={notFoundEn}
                        alt="lowongan-belum-ada"
                      />
                    )}
                  </Col>
                  <Col sm="3"></Col>
                </Row>
              ) : (
                <Fragment>
                  <Row className="md-company-header flex-column mb-3 mt-2">
                    <Col className="col-12 d-flex mb-1">
                      <h5 className="text-uppercase content-sub-title mb-0">
                        {t("Interview.resultVideo")}{" "}
                      </h5>
                    </Col>
                    {/* <img src={require("../../../../assets/img/16personalities/entj.png")} alt="transparent" /> */}
                    <Col className="col-12 d-flex mb-3 text-muted">
                      <i>{moment(createdAt).format("DD MMMM YYYY")}</i>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="6" md="8">
                      {activeVideo && (
                        <>
                          <div className="vidwrapper">
                            <video
                              ref={vidRef}
                              className="vid"
                              src={activeVideo}
                              width="100%"
                              height="100%"
                              id="dataVid"
                              controls
                              onPlaying={() => setShowButton(false)}
                              onPause={() => setShowButton(true)}
                            />
                            {showButton ? (
                              <>
                                <p className="title text-capitalize">
                                  &nbsp;&nbsp;&nbsp;{activeTitle}
                                  &nbsp;&nbsp;&nbsp;
                                </p>
                                <Button
                                  rounded="true"
                                  className="play"
                                  onClick={playVid}
                                >
                                  <i className="fa fa-2x fa-align-center fa-play"></i>
                                </Button>
                                <div className="vidwrapperWidya">
                                  <video
                                    ref={vidRef2}
                                    className="smallVid"
                                    src={activeWidya}
                                    onPlaying={() => setShowButton2(false)}
                                    onPause={() => setShowButton2(true)}
                                  />
                                  {showButton2 ? (
                                    <>
                                      <small className="question">
                                        {t("General.question")} :&nbsp;
                                      </small>
                                      <Button
                                        rounded="true"
                                        className="play2"
                                        onClick={playVid2}
                                      >
                                        <i className="fa fa-sm fa-align-center fa-play"></i>
                                      </Button>
                                    </>
                                  ) : (
                                    <Button
                                      rounded="true"
                                      className="pause"
                                      onClick={pauseVid2}
                                    >
                                      <i className="fa fa-sm fa-align-center fa-pause"></i>
                                    </Button>
                                  )}
                                </div>
                              </>
                            ) : null}
                          </div>
                        </>
                      )}
                    </Col>
                    <Col lg="6" md="4">
                      {activeSources &&
                        activeSources.map((video, idx) => (
                          <ListGroup
                            key={idx}
                            onClick={() => handleOnClick(idx)}
                            className={
                              activeIndex === idx ? "activeTitle" : "unactive"
                            }
                          >
                            <ListGroupItem
                              className="d-flex align-items-center"
                              onClick={() => changeVideo(video)}
                              key={video.title}
                              tag="button"
                              action
                            >
                              <i className="fa fa-video-camera mr-2" />
                              {idx + 1}. {video.title}
                              {video?.raw == null ? (
                                <i className="fa fa-hourglass-half text-info ml-auto"></i>
                              ) : (
                                <i className="flex-row-reverse fa fa-check-circle-o text-success ml-auto"></i>
                              )}
                            </ListGroupItem>
                          </ListGroup>
                        ))}
                    </Col>
                  </Row>
                </Fragment>
              )}
            </CardBody>
          </Card>
        </Container>
      )}
      <FooterLandingPage />
    </Fragment>
  );
};
const mapsToDispatch = (dispatch) => {
  return {
    setUser: (state) =>
      dispatch({
        type: "SET_USER",
        payload: state,
      }),
  };
};
export default connect(
  ({ user }) => ({ user }),
  mapsToDispatch
)(translate(InterviewOnline));
