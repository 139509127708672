import React from "react";
import Skeleton from "react-loading-skeleton";
import { Container, Row, Col, CardDeck, CardBody } from "reactstrap";
import { isBrowser, isMobile } from "react-device-detect";
const Home = () => {
  return (
    <Container fluid className="container-fluid-home">
      <Row>
        <Col md="12" className="menu ">
          <CardDeck className="all-menu">
            {isBrowser ? (
              Array(4)
                .fill()
                .map((item, index) => (
                  <Col
                    lg={3}
                    md={6}
                    sm={6}
                    xs={6}
                    key={index}
                    className="p-0 mt-4  "
                    style={{ float: "left" }}
                  >
                    <div className="card menu-item h-100 pb-2 ">
                      <div className="p-3">
                        <Skeleton height={200} width={"86%"} />
                      </div>
                      <CardBody>
                        <Skeleton height={30} width={100} />
                        <br />
                        <Skeleton width={150} />
                        <br />
                        <Skeleton width={130} />
                      </CardBody>
                    </div>
                  </Col>
                ))
            ) : isMobile ? (
              <Row>
                {Array(4)
                  .fill()
                  .map((item, index) => (
                    <Col
                      lg={3}
                      md={6}
                      sm={6}
                      xs={6}
                      key={index}
                      className="mt-4  "
                      style={{ float: "left" }}
                    >
                      <div className="card menu-item h-100 pb-2 ">
                        <div className="p-3">
                          <Skeleton height={130} width={"86%"} />
                        </div>
                        <CardBody>
                          <Skeleton height={30} width={100} />
                          <br />
                          <Skeleton width={120} />
                          <br />
                          <Skeleton width={100} />
                        </CardBody>
                      </div>
                    </Col>
                  ))}
              </Row>
            ) : null}
          </CardDeck>
        </Col>
      </Row>
    </Container>
  );
};
export default Home;
