import React, { useState, Fragment } from "react";
import { Link } from "react-router-dom";
import {
  UncontrolledDropdown,
  DropdownItem,
  DropdownMenu,
  Spinner,
  DropdownToggle,
  Nav,
  Col,
} from "reactstrap";
import PropTypes from "prop-types";
import { Formik, Field, Form } from "formik";
import FormikInput from "../../../../../utils/FormikInput";
import { Row, Button, Modal, ModalBody } from "reactstrap";
import { AppNavbarBrand } from "@coreui/react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import requestAuth from "../../../../../utils/RequestAuth";
import { logout } from "../../../../../config/Services/Actions/auth";
import { translate } from "react-switch-lang";
import langUtils from "../../../../../utils/SettingLanguage/index";
import logo from "../../../../../assets/img/logo_blue.png";
// import ShortGuideUser from "../../../../Templates/ShortGuideUser";
import ConfirmationModal from "../../../../../components/atoms/ConfirmationModal";
import moment from "../../../../../utils/Moment";
const DefaultHeader = (props) => {
  const [modalPass, setModalPass] = useState(false);
  const [loading, setLoading] = useState(false);
  const formValues = {
    currentPass: "",
    confirmPass: "",
    newPass: "",
  };
  const formValidate = (values) => {
    let errors = {};

    const validator = new RegExp(/^(?=.*\d)(?=.*[a-z]).{8,100}$/);
    if (values.newPass !== values.confirmPass) {
      errors.confirmPass = t("Register.notMatchPassword");
    } else if (values.newPass.length < 8) {
      errors.newPass = t("Register.passwordMinimum");
    } else if (!validator.test(values.newPass)) {
      errors.newPass = t("Register.passwordMinimum");
    }
    return errors;
  };

  const onSelectFlag = (countryCode) => {
    setLoading(true);
    let lang = new FormData();
    lang.append("language", countryCode.toLowerCase());
    requestAuth
      .post("/language", lang)
      .then((res) => {
        handleSetLanguage(countryCode);
        setLoading(false);
      })
      .catch((err) => {
        toast.error(t("failedChangeLanguage"));
        setLoading(false);
      });
  };
  const handleSetLanguage = (key) => {
    moment.locale(key.toLowerCase());
    langUtils.setLanguage(key);
  };

  const toggleChangePass = () => {
    setModalPass(!modalPass);
  };

  const formSubmit = async (values, { setSubmitting, setErrors }) => {
    const dataObject = {
      current: values.currentPass,
      new: values.newPass,
    };
    await requestAuth
      .post("/changepassword", dataObject)
      .then((res) => {
        // if (res.response.data === 200) {
        setModalPass(true);
        try {
          props.logout();
        } catch (err) {
          window.location.reload();
        } finally {
          setSubmitting(false);
        }
        // }
      })
      .catch((err) => {
        toast.error(JSON.stringify(err.response.data.message), {
          autoClose: 3000,
        });
        setSubmitting(false);
      });
  };

  function Kapital(str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  const logout = async (e) => {
    e.preventDefault();
    try {
      props.logout();
    } catch (err) {
      window.location.reload();
    }
  };

  // onSelectFlag = (countryCode) => {
  //   this.handleSetLanguage(countryCode);
  //   sessionStorage.setItem("language", countryCode);
  // }
  // const handleSetLanguage = (key) => {
  //   setLanguage(key);
  // };

  // eslint-disable-next-line
  const { t } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [hintNumber, setHintNumber] = useState(0);
  const open = () => {
    setIsOpen(!isOpen);
    setHintNumber(1);
  };
  function popUp() {
    if (isOpen) {
      if (hintNumber === 1) {
        return (
          <ConfirmationModal
            guide={1}
            isOpen={isOpen}
            title={t("UserGuide.helpWelcome")}
            desc={t("UserGuide.helpWelcome2")}
            images={true}
            titleLeft={t("UserGuide.helpNextTime")}
            titleRight={t("UserGuide.helpContinue")}
            center={true}
            onPressRight={() => {
              setHintNumber(2);
            }}
            onPressLeft={() => setIsOpen(false)}
          />
        );
      }
      if (hintNumber === 2) {
        return (
          <ConfirmationModal
            guide={2}
            isOpen={isOpen}
            title={t("UserGuide.helpExploreSkillana")}
            desc={t("UserGuide.helpReady")}
            images={true}
            titleLeft={t("UserGuide.helpBack")}
            titleRight={t("UserGuide.helpContinue")}
            center={true}
            onPressRight={() => {
              setHintNumber(3);
            }}
            onPressLeft={() => setHintNumber(1)}
          />
        );
      }
      if (hintNumber === 3) {
        return (
          <ConfirmationModal
            guide={3}
            isOpen={isOpen}
            title={t("UserGuide.helpExploreFirstStep")}
            desc={t("UserGuide.helpExploreFirstStepDesc")}
            images={true}
            titleLeft={t("UserGuide.helpBack")}
            titleRight={t("UserGuide.helpContinue")}
            center={true}
            onPressRight={() => {
              setHintNumber(4);
            }}
            onPressLeft={() => setHintNumber(2)}
          />
        );
      }
      if (hintNumber === 4) {
        return (
          <ConfirmationModal
            guide={4}
            isOpen={isOpen}
            title={t("UserGuide.helpExploreSecondStep")}
            desc={t("UserGuide.helpExploreSecondStepDesc")}
            images={true}
            titleLeft={t("UserGuide.helpBack")}
            titleRight={t("UserGuide.helpContinue")}
            center={true}
            onPressRight={() => {
              setHintNumber(5);
            }}
            onPressLeft={() => setHintNumber(3)}
          />
        );
      }
      if (hintNumber === 5) {
        return (
          <ConfirmationModal
            guide={5}
            isOpen={isOpen}
            title={t("UserGuide.helpExploreThirdStep")}
            desc={t("UserGuide.helpExploreThirdStepDesc")}
            images={true}
            titleLeft={t("UserGuide.helpBack")}
            titleRight={t("UserGuide.helpContinue")}
            center={true}
            onPressRight={() => {
              setHintNumber(6);
            }}
            onPressLeft={() => setHintNumber(4)}
          />
        );
      }
      if (hintNumber === 6) {
        return (
          <ConfirmationModal
            guide={6}
            isOpen={isOpen}
            title={t("UserGuide.helpExploreLastStep")}
            desc={t("UserGuide.helpExploreLastStepDesc")}
            images={true}
            titleLeft={t("UserGuide.helpBack")}
            titleRight={t("UserGuide.helpOk")}
            center={true}
            onPressRight={() => {
              setIsOpen(false);
            }}
            onPressLeft={() => setHintNumber(5)}
          />
        );
      }
    }
  }

  return (
    <React.Fragment>
      {popUp()}
      <Link to="/home">
        <AppNavbarBrand
          to={"/"}
          full={{ src: logo, alt: "Skillana Logo", width: 130 }}
          minimized={{ src: logo, alt: "Skillana Icon", width: 120 }}
          style={{ cursor: "pointer" }}
        />
      </Link>

      <Nav className="ml-auto" navbar>
        {/* <div className="navtext d-none d-xl-block || d-none d-lg-block d-xl-none ml-auto mr-2">
          {Kapital(props.user.fullName)}
        </div> */}
        <UncontrolledDropdown nav direction="down">
          <DropdownToggle nav>
            {loading ? <Spinner size="sm" /> : langUtils.getLanguage()}
          </DropdownToggle>
          <DropdownMenu right>
            <DropdownItem header>{t("General.chooseLanguage")}</DropdownItem>
            <DropdownItem onClick={() => onSelectFlag("ID")}>
              Bahasa Indonesia (ID)
            </DropdownItem>
            <DropdownItem onClick={() => onSelectFlag("EN")}>
              English (US)
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
        <UncontrolledDropdown nav direction="down">
          <DropdownToggle nav>
            {props.user.avatar === null ? (
              <div className="round-100 bg-user"></div>
            ) : (
              <div
                className="round-100"
                style={{
                  backgroundImage: `url(${
                    process.env.REACT_APP_DOMAIN + "" + props.user.avatar
                  })`,
                }}
              ></div>
            )}
          </DropdownToggle>
          <DropdownMenu right>
            <DropdownItem
              className="d-block d-sm-none || d-none d-sm-block d-md-none || d-none d-md-block d-lg-none"
              style={{ backgroundColor: "#1B568D", color: "white" }}
              disabled
            >
              <i>
                {props.user.avatar === null ? (
                  <div className="iconNavbar round-100 bg-user" />
                ) : (
                  <div
                    className="iconNavbar round-100"
                    style={{
                      backgroundImage: `url(${
                        process.env.REACT_APP_DOMAIN + "" + props.user.avatar
                      })`,
                    }}
                  ></div>
                )}
              </i>
              {props.user.fullName}
            </DropdownItem>
            <DropdownItem
              tag="div"
              className="dropdown-profile-header text-capitalize px-3"
              header
              style={{ backgroundColor: "#305574", color: "#fff" }}
            >
              <div
                style={{
                  textOverflow: "ellipsis",
                  width: 200,
                  overflowX: "hidden",
                }}
              >
                <strong>{Kapital(props?.user?.fullName)}</strong>
              </div>
            </DropdownItem>
            {!localStorage.getItem("referal") ? (
              <DropdownItem onClick={open}>
                <i className="fa fa-question"></i>
                {t("General.hint")}
              </DropdownItem>
            ) : null}
            <DropdownItem onClick={toggleChangePass}>
              <i className="fa fa-key"></i>
              {t("General.changePassword")}
            </DropdownItem>
            <DropdownItem onClick={logout}>
              <i className="fa fa-lock"></i>
              {t("General.logout")}
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </Nav>

      {/*Change Pass*/}
      <Modal isOpen={modalPass} toggle={toggleChangePass}>
        <ModalBody>
          <h5 className="content-sub-title mb-4">
            {t("General.changePassword")}
          </h5>
          <Formik
            initialValues={formValues}
            validate={formValidate}
            onSubmit={formSubmit}
            render={({ isSubmitting }) => (
              <Form>
                <Fragment>
                  <Row>
                    <div className="col-md-12">
                      <Col sm="12" className="mb-3 p-0">
                        <Field
                          type="password"
                          label={t("General.oldPassword")}
                          name="currentPass"
                          id="currentPass"
                          component={FormikInput}
                        />
                      </Col>
                      <Col sm="12" className="mb-3 p-0">
                        <Field
                          type="password"
                          label={t("General.newPassword")}
                          name="newPass"
                          id="newPass"
                          component={FormikInput}
                        />
                      </Col>
                      <Col sm="12" className="mb-3 p-0">
                        <Field
                          type="password"
                          label={"General.repassword"}
                          name="confirmPass"
                          id="confirmPass"
                          component={FormikInput}
                        />
                      </Col>
                    </div>
                  </Row>
                  <Row>
                    <div className="col-12 d-flex justify-content-end">
                      <div>
                        <Button
                          className="mr-2"
                          color="white"
                          onClick={toggleChangePass}
                        >
                          {t("General.cancel")}
                        </Button>
                        <Button
                          type="submit"
                          color="netis-color"
                          disabled={isSubmitting}
                          style={{ border: "none", fontWeight: "bold" }}
                        >
                          {isSubmitting ? (
                            <Spinner color="light" size="sm" />
                          ) : (
                            t("General.changePassword")
                          )}
                        </Button>
                      </div>
                    </div>
                  </Row>
                </Fragment>
              </Form>
            )}
          />
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

DefaultHeader.propTypes = {
  t: PropTypes.func.isRequired,
};
export default connect(({ user }) => ({ user }), { logout })(
  translate(DefaultHeader)
);
