import React, { useState, useEffect } from "react";
import { Container, Row, Col, Spinner, Input, Form } from "reactstrap";
import { translate, t } from "react-switch-lang";
import request from "../../../../../utils/Request";
import AffirmationModal from "../../../../../components/atoms/AffirmationModal";
import { useResultTestContext } from "../../Menu/AgilityTestContext";
import { toast } from "react-toastify";
import moment from "../../../../../utils/Moment";
toast.configure();
function BedheAksara(props) {
  // eslint-disable-next-line
  const [result, setResult] = useResultTestContext({});
  const [errorInput, setErrorInput] = useState(false);
  const [answers, setAnswers] = useState({});
  const [time, setTime] = useState({});
  const [startTime, setStartTime] = useState(props.startTime);
  const [answersNow, setAnswersNow] = useState([]);
  const [answersArray, setAnswersArray] = useState({});
  const [error, setError] = useState(false);
  const [btnDisable, setBtnDisbale] = useState(true);
  const [loading, setLoading] = useState(true);
  const [questions, setQuestions] = useState(null);
  const [page, setPage] = useState(0);
  const [pageTitle, setPageTitle] = useState(1);
  const [completed, setCompleted] = useState(false);
  const [chance, setChance] = useState(3);
  const [isEmpty, setIsEmpty] = useState(false);

  useEffect(() => {
    request
      .get("/assessment/test/agility/bedhe_aksara")
      .then((res) => {
        if (res.data?.data) {
          setLoading(false);
          setQuestions(res.data.data);
        }
      })
      .catch((err) => {
        setError(true);
      }); // eslint-disable-next-line
  }, []);
  function isError() {
    if (error) {
      return (
        <AffirmationModal
          isOpen={error}
          title={"Error !"}
          desc={t("Agility.errorConnection")}
          titleButton={t("General.okey")}
          toggleOpen={() => {
            setError(true);
          }}
          confirmation={() => {
            window.location.reload();
            setError(false);
          }}
        />
      );
    }
  }
  function isErrorInput() {
    if (errorInput) {
      return (
        <AffirmationModal
          isOpen={errorInput}
          title={"Error !"}
          desc={t("Agility.hasDataBedhe")}
          titleButton={t("General.okey")}
          toggleOpen={() => {
            setErrorInput(true);
          }}
          confirmation={() => {
            setErrorInput(false);
          }}
        />
      );
    }
  }

  function isComplete() {
    if (completed) {
      return (
        <AffirmationModal
          isOpen={completed}
          title={"Success !"}
          desc={t("Agility.completeBedhe")}
          titleButton={t("General.okey")}
          toggleOpen={() => {
            setCompleted(true);
          }}
          confirmation={() => {
            let totalTime = 0;
            Object.values(time).map((data) => {
              totalTime = totalTime + parseInt(data);
              return true;
            });
            setResult((result) => ({
              ...result,
              bedhe_aksara: { answers, time, totalTime, step: props.page },
            }));
            window.scroll({ top: 0, behavior: "smooth" });
            props.setNextPage(false);
            toast.success("Success", {
              autoClose: 3000,
            });
          }}
        />
      );
    }
  }

  useEffect(() => {
    // eslint-disable-next-line
    if (Object.values(time).length > 0) {
      setStartTime(moment().format("YYYY-MM-DD HH:mm:ss"));
    }
  }, [time]);

  function changeInput(e, index) {
    let values = e.target.value.toLowerCase();
    const re = /^[A-Za-z\b]+$/;
    if (e.target.value === "" || e.target.value === null) {
      values = null;
      setAnswersArray((state) => ({ ...state, [index]: values }));
    } else {
      if (re.test(values)) {
        setAnswersArray((state) => ({ ...state, [index]: values }));
      } else {
        e.target.value = null;
        setErrorInput(true);
        return false;
      }
    }
  }

  function handleMoveInput(e) {
    const { maxLength, value, name } = e.target;
    // eslint-disable-next-line
    const [fieldName, fieldIndex] = name.split("-");
    let fieldIntIndex = parseInt(fieldIndex, 4);
    const nextField = document.querySelector(
      `input[name=field-${fieldIntIndex + 1}]`
    );
    const prevField = document.querySelector(
      `input[name=field-${fieldIntIndex - 1}]`
    );

    if (value.length >= maxLength) {
      if (pageTitle < 4) {
        if (fieldIntIndex < 2) {
          nextField.focus();
        }
      } else {
        if (fieldIntIndex < 3) {
          nextField.focus();
        }
      }
    }

    if (value.length === 0) {
      setIsEmpty(true);
    }
    // if current input value is null and keypress backspace, move to prev input
    if (isEmpty && e.key === "Backspace") {
      if (fieldIntIndex > 0) {
        prevField.focus();
        setIsEmpty(false);
      }
    }
  }

  console.log(answersArray);
  useEffect(() => {
    // eslint-disable-next-line
    if (Object.values(answersArray).length > 0) {
      let complete = 1;
      if (
        Object.values(answersArray).length ===
        parseInt(questions[page].boxInput)
      ) {
        // eslint-disable-next-line
        Object.values(answersArray).map((data) => {
          if (data === null) {
            complete = 0;
          }
        });
        console.log(complete);
        if (complete === 1) {
          setBtnDisbale(false);
        } else {
          setBtnDisbale(true);
        }
      } else {
        setBtnDisbale(true);
      }
    } else {
      setBtnDisbale(true);
    }
    // eslint-disable-next-line
  }, [answersArray]);

  useEffect(() => {
    // eslint-disable-next-line
    if (chance === 0) {
      setTime((state) => ({
        ...state,
        [pageTitle]: moment(new Date(), "YYYY-MM-DD HH:mm:ss").diff(
          startTime,
          "seconds"
        ),
      }));
      if (questions[page].lastQuestion === false) {
        setAnswers((state) => ({
          ...state,
          [pageTitle]: answersNow,
        }));
        setLoading(false);
      } else {
        setAnswers((state) => ({
          ...state,
          [pageTitle]: answersNow,
        }));
        setLoading(false);
        setCompleted(true);
      }
    }
    // eslint-disable-next-line
  }, [chance]);

  useEffect(() => {
    // eslint-disable-next-line
    if (Object.values(answers).length > 0) {
      if (questions[page].lastQuestion === false) {
        setAnswersNow([]);
        setPage(page + 1);
        setPageTitle(pageTitle + 1);
        setChance(3);
        setLoading(false);
      }
    } // eslint-disable-next-line
  }, [answers]);

  useEffect(() => {
    // eslint-disable-next-line
    if (answersNow.length > 0) {
      let answer = [];
      Object.values(answersArray).map((data, idx) => {
        answer[idx] = data;
        return true;
      });
      console.log(answer);
      answer = answer.toString();
      let resultss = answer.replaceAll(",", "");
      request
        .post("/assessment/test/agility/bedhe_aksara/validate", {
          page: pageTitle,
          answer: resultss,
        })
        .then((res) => {
          if (res.data?.success) {
            toast.success(t("Success"), {
              autoClose: 3000,
            });
            setTime((state) => ({
              ...state,
              [pageTitle]: moment(new Date(), "YYYY-MM-DD HH:mm:ss").diff(
                startTime,
                "seconds"
              ),
            }));
            setAnswersArray({});
            if (questions[page].lastQuestion === false) {
              setAnswers((state) => ({
                ...state,
                [pageTitle]: answersNow,
              }));
              setLoading(false);
            } else {
              setAnswers((state) => ({
                ...state,
                [pageTitle]: answersNow,
              }));
              setLoading(false);
              setCompleted(true);
            }
          }
        })
        .catch((err) => {
          setAnswersArray({});
          setChance(chance - 1);
          setLoading(false);
          toast.error(t("Agility.answerFalse"), {
            autoClose: 3000,
          });
        }); // eslint-disable-next-line
    } // eslint-disable-next-line
  }, [answersNow]);
  function nextPage() {
    setLoading(true);
    let complete = 1;
    window.scroll({ top: 0, behavior: "smooth" });
    // eslint-disable-next-line
    Object.values(answers).map((data) => {
      if (data === null) {
        complete = 0;
      }
    });
    if (complete === 0) {
      setErrorInput(true);
      setLoading(false);
      return false;
    } else {
      let answer = [];
      Object.values(answersArray).map((data, idx) => {
        answer[idx] = data;
        return true;
      });
      console.log(answer);
      answer = answer.toString();
      let result = answer.replaceAll(",", "");
      setAnswersNow((state) => [...state, result]);
    }
  }
  console.log(answersNow);
  // console.log(answers);
  return (
    <Container>
      {isError()}
      {isErrorInput()}
      {isComplete()}
      <Form>
        <Row>
          <Col xs="0" sm="1"></Col>
          <Col xs="12" sm="10" className=" mt-4 mb-4">
            <h3>
              <b>
                {props.title} ( {pageTitle} / 4 )
              </b>
            </h3>
            <br />
            <Row
              className="p-md-5"
              style={{
                boxShadow: "0px 0px 4px 2px rgba(0, 0, 0, 0.1)",
                borderRadius: "12px",
                padding: "2rem 0",
              }}
            >
              {loading ? (
                <Col sm="12" className="text-center">
                  <Spinner size="lg" />
                </Col>
              ) : questions ? (
                questions[page] ? (
                  <>
                    <Col lg="12" className="text-center pt-4 pb-4">
                      <Row>
                        <Col lg="12" className="text-right p-0">
                          <h4>
                            {t("Agility.chance")}:{" "}
                            {Array(chance)
                              .fill()
                              .map((data, idx) => {
                                return (
                                  <i
                                    className="fa fa-heart mr-3"
                                    style={{ color: "red" }}
                                    key={idx}
                                  ></i>
                                );
                              })}
                            {Array(3 - chance)
                              .fill()
                              .map((data, idx) => {
                                return (
                                  <i
                                    className="fa fa-heart mr-3"
                                    style={{ color: "black" }}
                                    key={idx}
                                  ></i>
                                );
                              })}
                          </h4>
                        </Col>
                        <Col lg="4"></Col>
                        <Col lg="4" className="text-center p-0">
                          <div className="bg-lock">
                            <div className="wrap-input">
                              {Array(parseInt(questions[page].boxInput))
                                .fill()
                                .map((item, index) => {
                                  return (
                                    <Input
                                      key={index}
                                      id={index}
                                      type="text"
                                      name={`field-${index}`}
                                      autoComplete="off"
                                      className="input-bedhe"
                                      maxLength={1}
                                      onChange={(e) => {
                                        changeInput(e, index);
                                        setIsEmpty(false);
                                      }}
                                      onKeyUp={(e) => {
                                        handleMoveInput(e);
                                      }}
                                    />
                                  );
                                })}
                            </div>
                          </div>
                        </Col>
                        <Col lg="4"></Col>
                      </Row>
                    </Col>
                    <Col lg="12" className="pt-4 pb-4">
                      <h4>
                        <b>{t("Agility.hint")}:</b>
                      </h4>
                      <ul>
                        {questions[page].hint.map((data, idx) => {
                          return <li key={idx}>{data}</li>;
                        })}
                      </ul>
                    </Col>
                    <Col lg="12" className="text-center pt-3">
                      <button
                        type="reset"
                        className="btn btn-primary btn-lg"
                        onClick={nextPage}
                        disabled={btnDisable}
                      >
                        {questions[page].lastQuestion
                          ? "Submit"
                          : t("General.next")}{" "}
                        <i className="fa fa-arrow-right ml-2"></i>
                      </button>
                    </Col>
                  </>
                ) : null
              ) : null}
            </Row>
          </Col>
          <Col xs="0" sm="1"></Col>
        </Row>
      </Form>
    </Container>
  );
}

export default translate(BedheAksara);
