import React, { useState } from "react";
import { useEffect } from "react";
import { Alert } from "reactstrap";
import request from "../../../../../../utils/Request";
import LoadingAnimation from "../../../../../../components/atoms/LoadingAnimation";
import DiscResult from "../../../../../Templates/ResultAssessment/ResultAssessmentDisc";
import moment from "../../../../../../utils/Moment";
import { translate } from "react-switch-lang";
function Disc(props) {
  const { t } = props;
  const [loading, setLoading] = useState(true);
  const [result, setResult] = useState(null);
  moment.locale("id");
  useEffect(() => {
    request
      .get("/assessment/test/disc")
      .then((res) => {
        if (res.data?.data) {
          setResult(null);
          setLoading(false);
        }
      })
      .catch((err) => {
        if (
          err.response?.status === 422 &&
          err.response.data.data.error_type === "HAS_DONE_TEST"
        ) {
          const resultExpiredDateTime = new Date(
            err.response.data.data.result_expired_at
          );
          request.get("/assessment/test/disc/result").then((res) => {
            const resultResponse = res.data.data;
            resultResponse["expired_at"] = resultExpiredDateTime;
            resultResponse["message"] = res.data.message;
            setResult(resultResponse);
            setLoading(false);
          });
        } else {
          setLoading(false);
          return Promise.reject(err);
        }
      });
  }, []);

  if (loading) {
    return <LoadingAnimation />;
  }

  return (
    <div className="pb-5 animated fadeIn">
      {result ? (
        <DiscResult result={result} back={false} />
      ) : (
        <Alert color="danger" className="text-center mt-3 mb-4">
          <h6 className="content-title mb-2">
            {t("Assessment.sorryNoAssessmentPersonality")}
          </h6>
        </Alert>
      )}
    </div>
  );
}

export default translate(Disc);
