import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Alert,
  Button,
  Container,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { Link } from "react-router-dom";
import StarRatingComponent from "react-star-rating-component";
import { translate, t } from "react-switch-lang";
import request from "../../../../utils/Request";
import { useInvoiceBusinessInsight } from "../../../../hooks/useInvoiceBusinessInsight";
import LoadingAnimation from "../../../../components/atoms/LoadingAnimation";
import Spinner from "reactstrap/lib/Spinner";
import { isMobile } from "react-device-detect";
export default translate(function ResultAssesmentBusinessInsight(props) {
  const [result, setResult] = useState(null);
  const [loadingWeb, setLoading] = useState(true);
  const [loadingButton, setLoadingButton] = useState(false);
  const { data, loading, error } = useInvoiceBusinessInsight([]);
  const [modal, setModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [openKey, setOpenKey] = useState(false);
  useEffect(() => {
    request
      .get("/assessment/test/business-insight/result")
      .then((res) => {
        setResult(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (result !== null) {
      request
        .get("/assessment/test/business-insight/result")
        .then((res) => {
          setResult(res.data.data);
        })
        .catch((err) => {});
    }
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  const dataList = [
    {
      title: "Autonomy",
      text: result?.autonomy ?? null,
      rating: result?.star?.autonomy ?? 0,
      desc: t("Assessment.autonomy"),
      name: "autonomy",
    },
    {
      title: "Innovativeness",
      text: result?.innovativeness ?? null,
      rating: result?.star?.innovativeness ?? 0,
      desc: t("Assessment.innovativness"),
      name: "innovativeness",
    },
    {
      title: "Risk Taking",
      text: result?.risk_taking ?? null,
      rating: result?.star?.risk_taking ?? 0,
      desc: t("Assessment.risk"),
      name: "risk",
    },
    {
      title: "Proactiveness",
      text: result?.proactiveness ?? null,
      rating: result?.star?.proactiveness ?? 0,
      desc: t("Assessment.proactiveness"),
      name: "proactiveness",
    },
    {
      title: "Competitive Aggressiveness",
      text: result?.competitive_agresiveness ?? null,
      rating: result?.star?.competitive_agresiveness ?? 0,
      desc: t("Assessment.competitive"),
      name: "competitive",
    },
    {
      title: t("General.conclusionSuggestionTitle"),
      text1: result?.conclusion?.high + result?.conclusion?.low ?? null,
      text2: result?.suggestion?.high + result?.suggestion?.low ?? null,
      rating: 0,
      desc: t("General.conclusionSuggestion"),
      name: "conclusion",
    },
  ];

  function purchased() {
    setLoadingButton(true);
    request
      .post("/invoice/create", {
        model: "business-insight",
        modelId: props.result["id"],
      })
      .then((res) => {
        console.log(res);
        window.open(res.data.url, "_self");
        setLoadingButton(false);
      })
      .catch((err) => {
        if (err.response?.status === 422) {
          window.alert("ERROR");
        }
        Promise.reject(err);
      });
  }

  const toggle = () => setModal(!modal);
  function openDetail(key) {
    if (isMobile) {
      setOpenKey(key);
      setModal(true);
    } else {
      setOpen(true);
      setOpenKey(key);
    }
  }
  function closeDetail() {
    setOpenKey(false);
    setOpen(false);
  }
  return (
    <Container>
      {loadingWeb ? (
        <LoadingAnimation />
      ) : loading ? (
        <LoadingAnimation />
      ) : error ? (
        <div className="alert alert-danger">
          <h5>Error !</h5>
        </div>
      ) : (
        <div
          className="justify-content-center pb-4"
          style={{ paddingTop: "2rem" }}
        >
          <Row className="mb-4">
            <Col sm="2">
              {props.back ? (
                <Link to="/assessment">
                  <Button className="navtext btn btn-lg btn-netis-color mb-3">
                    <i className="fa fa-arrow-left mr-2"></i>
                    {t("General.back")}
                  </Button>
                </Link>
              ) : null}
            </Col>
            <Col sm="8">
              <Row>
                <Col sm="2" />
                <Col sm="8">
                  <Alert
                    color="success"
                    className="text-center mb-3"
                    style={{
                      width: "100%",
                      marginRight: "auto",
                      marginLeft: "auto",
                    }}
                  >
                    <h5 className="content-title mb-2">
                      {t("Assessment.completeTest")} <br />{" "}
                      {t("Assessment.businessInsightTitle")}.
                    </h5>
                    <p>
                      <b>{props.message}</b>
                    </p>
                  </Alert>
                  <Col sm="2" />
                </Col>
              </Row>
            </Col>
            <Col sm="2" className="mb-3"></Col>
          </Row>

          <Row>
            <Col xs="1" md="0" lg="1" />
            <Col xs="10" md="12" lg="10">
              <div className="text-center">
                <Container>
                  <h3 className="mb-5" style={{ fontWeight: "bold" }}>
                    {t("Assessment.businessInsightTestResult")}
                  </h3>
                  <div className="mb-5">
                    <img
                      src={require("../../../../assets/img/business_insight_done.png")}
                      width="70%"
                      alt="business insight done"
                    />
                  </div>
                  {data?.paid ? (
                    <Row>
                      {dataList.map((data, key) => {
                        return (
                          <>
                            <Col md="4" className="text-center pt-2 pb-2">
                              <div
                                className={
                                  openKey === key
                                    ? "bg-open p-3"
                                    : "bg-no-open p-3"
                                }
                              >
                                <div
                                  style={{
                                    border: "1px solid #F2C94C",
                                    boxSizing: "border-box",
                                    borderRadius: "12px",
                                    padding: "10px 5px",
                                    minHeight: "300px",
                                    backgroundColor: "#fff",
                                  }}
                                  className={
                                    openKey === key && !isMobile
                                      ? "cursor-pointer"
                                      : null
                                  }
                                  onClick={
                                    openKey === key && !isMobile
                                      ? closeDetail
                                      : null
                                  }
                                >
                                  <p className="text-right">
                                    <i
                                      id={"UncontrolledTooltip" + key}
                                      className="fa fa-question-circle mr-2"
                                      style={{
                                        position: "relative",
                                        top: "1px",
                                        color: "#1472BA",
                                        fontSize: "15px",
                                      }}
                                    />
                                  </p>
                                  <UncontrolledTooltip
                                    target={"UncontrolledTooltip" + key}
                                  >
                                    {data?.desc}
                                  </UncontrolledTooltip>
                                  {openKey === key && !isMobile ? (
                                    <>
                                      <img
                                        src={require("../../../../assets/img/business/" +
                                          data?.name +
                                          ".png")}
                                        className="img-icon-agility"
                                        alt={data?.name}
                                      />
                                    </>
                                  ) : (
                                    <>
                                      <img
                                        src={require("../../../../assets/img/business/" +
                                          data?.name +
                                          ".png")}
                                        height="90px"
                                        alt={data?.name}
                                      />

                                      <h6 className="mt-3 mb-2">
                                        {data?.title}
                                      </h6>
                                      {data.name === "conclusion" ? (
                                        <>
                                          <br />
                                          <br />
                                          <div className="p-1"></div>
                                        </>
                                      ) : (
                                        <p
                                          style={{
                                            fontSize: "15px",
                                          }}
                                          className="mb-2"
                                        >
                                          <StarRatingComponent
                                            starCount={5}
                                            value={data?.rating}
                                          />
                                        </p>
                                      )}
                                      <button
                                        className="btn btn-primary btn-skillana mb-3"
                                        onClick={() => openDetail(key)}
                                      >
                                        <b>{t("General.seeResult")}</b>
                                      </button>
                                    </>
                                  )}
                                </div>
                              </div>
                            </Col>
                            {key === 2 ? (
                              <Col
                                sm="12"
                                className={
                                  open && openKey < 3 && openKey >= 0
                                    ? "d-block"
                                    : "d-none"
                                }
                              >
                                <div
                                  className="p-3 text-left mb-3"
                                  style={{
                                    boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.1)",
                                    borderRadius: "12px",
                                    backgroundColor: "#F2F8FE",
                                  }}
                                >
                                  <h5
                                    className="mt-3 mr-2"
                                    style={{ float: "left" }}
                                  >
                                    <b>{dataList[openKey]?.title}</b>
                                  </h5>
                                  <p
                                    style={{
                                      fontSize: "15px",
                                      marginTop: "15px",
                                    }}
                                  >
                                    <StarRatingComponent
                                      starCount={5}
                                      value={dataList[openKey]?.rating}
                                    />
                                  </p>
                                  <p>{dataList[openKey]?.text}</p>
                                </div>
                              </Col>
                            ) : null}
                            {key === 5 ? (
                              <Col
                                sm="12"
                                className={
                                  open && openKey < 6 && openKey > 2
                                    ? "d-block"
                                    : "d-none"
                                }
                              >
                                <div
                                  className="p-3 text-left mb-3"
                                  style={{
                                    boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.1)",
                                    borderRadius: "12px",
                                    backgroundColor: "#F2F8FE",
                                  }}
                                >
                                  <h5
                                    className="mt-3 mr-2"
                                    style={{ float: "left" }}
                                  >
                                    <b>{dataList[openKey]?.title}</b>
                                  </h5>
                                  {openKey !== 5 ? (
                                    <p
                                      style={{
                                        fontSize: "15px",
                                        marginTop: "15px",
                                      }}
                                    >
                                      <StarRatingComponent
                                        starCount={5}
                                        value={dataList[openKey]?.rating}
                                      />
                                    </p>
                                  ) : (
                                    <br />
                                  )}

                                  {openKey !== 5 ? (
                                    <p> {dataList[openKey]?.text} </p>
                                  ) : (
                                    <>
                                      <br />
                                      <br />
                                      <p>{dataList[openKey]?.text1}</p>
                                      <br />
                                      <p>{dataList[openKey]?.text2}</p>
                                    </>
                                  )}
                                </div>
                              </Col>
                            ) : null}
                          </>
                        );
                      })}
                    </Row>
                  ) : (
                    <>
                      <Row>
                        {dataList.map((data, key) => {
                          return (
                            <Col md="6" className="text-left" key={key}>
                              {data.name === "conclusion" ? null : (
                                <Row>
                                  <Col sm="6">
                                    <h6>{data.title}</h6>
                                  </Col>
                                  <Col sm="6">
                                    <p
                                      style={{
                                        fontSize: "25px",
                                        marginTop: "-10px",
                                      }}
                                    >
                                      <StarRatingComponent
                                        starCount={5}
                                        value={data?.rating}
                                      />
                                    </p>
                                  </Col>
                                </Row>
                              )}
                            </Col>
                          );
                        })}
                      </Row>
                      <div
                        className="text-center p-4 border"
                        style={{ fontFamily: "Poppins, sans-serif" }}
                      >
                        {t("Assessment.paidBusineeInsight2")}
                        <br />
                        <br />
                        <button
                          className="btn btn-success btn-skillana p-3"
                          onClick={purchased}
                          disabled={loadingButton}
                        >
                          {loadingButton ? (
                            <Spinner />
                          ) : (
                            <h5 className="m-0">
                              <b>{t("General.seeDetail")}</b>
                            </h5>
                          )}
                        </button>
                      </div>
                    </>
                  )}
                </Container>
              </div>
              <br />
            </Col>
            <Col xs="1" md="0" lg="1" />
          </Row>
          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle}>
              <h5 className="mt-3 mr-2" style={{ float: "left" }}>
                <b>{dataList[openKey]?.title}</b>
              </h5>

              {openKey !== 5 ? (
                <p
                  style={{
                    fontSize: "15px",
                    marginTop: "15px",
                  }}
                >
                  <StarRatingComponent
                    starCount={5}
                    value={dataList[openKey]?.rating}
                  />
                </p>
              ) : null}
            </ModalHeader>
            <ModalBody>
              {openKey !== 5 ? (
                <p> {dataList[openKey]?.text} </p>
              ) : (
                <>
                  <p>{dataList[openKey]?.text1}</p>

                  <p>{dataList[openKey]?.text2}</p>
                </>
              )}
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={toggle}>
                Close
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      )}
    </Container>
  );
});
