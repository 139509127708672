import React, { useState, useEffect } from "react";
import ReactMarkdown from "react-markdown";
import {
  Card,
  Row,
  Col,
  CardBody,
  CardHeader,
  Container,
  Button,
  DropdownItem,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap";
import { translate, t } from "react-switch-lang";
import { Link } from "react-router-dom";
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import request from "../../../../utils/Request";
function ResultAssesmentDisc({ result, back }) {
  const resultType = result.type_description[0];
  const results = resultType[0];
  const [kekuatan, setKekuatan] = useState([]);
  const [karakter, setKarakter] = useState([]);
  const [perkembangan, setPerkembangan] = useState([]);
  const [token, setToken] = useState(null);
  useEffect(() => {
    request
      .get("/token/" + result.id)
      .then((res) => {
        setToken(res.data);
      })
      .catch((err) => {});
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    const arrKekuatan = results.kekuatan.split("\n");
    if (arrKekuatan.length >= 10) {
      const chunkLength = Math.max(arrKekuatan.length / 2, 1);
      let chunks = [];
      for (var i = 0; i < 2; i++) {
        if (chunkLength * (i + 1) <= arrKekuatan.length)
          chunks.push(
            arrKekuatan.slice(chunkLength * i, chunkLength * (i + 1))
          );
      }
      setKekuatan(chunks);
    }
  }, [results.kekuatan]);

  useEffect(() => {
    const arrKarakter = results.karakteristik.split("\n");
    if (arrKarakter.length >= 10) {
      const chunkLength = Math.max(arrKarakter.length / 2, 1);
      let chunks = [];
      for (var i = 0; i < 2; i++) {
        if (chunkLength * (i + 1) <= arrKarakter.length)
          chunks.push(
            arrKarakter.slice(chunkLength * i, chunkLength * (i + 1))
          );
      }
      setKarakter(chunks);
    }
  }, [results.karakteristik]);

  useEffect(() => {
    const arrPerkembangan = results.area_perkembangan.split("\n");
    if (arrPerkembangan.length >= 10) {
      const chunkLength = Math.max(arrPerkembangan.length / 2, 1);
      let chunks = [];
      for (var i = 0; i < 2; i++) {
        if (chunkLength * (i + 1) <= arrPerkembangan.length)
          chunks.push(
            arrPerkembangan.slice(chunkLength * i, chunkLength * (i + 1))
          );
      }
      setPerkembangan(chunks);
    }
  }, [results.area_perkembangan]);

  // const [name, setName] = useState('Copy Link')
  const diKlik = () => {
    toast.success(t("JobVacancy.alreadyCopy"), { autoClose: 2000 });
  };
  const [dropdownOpen, setOpen] = useState(false);
  const toggle = () => setOpen(!dropdownOpen);

  return (
    <>
      <Container>
        <div className="justify-content-center" style={{ paddingTop: "2rem" }}>
          <Row>
            <Col sm="3">
              {back ? (
                <Link to="/assessment">
                  <Button className="navtext btn btn-lg btn-netis-color mb-3 ">
                    <i className="fa fa-arrow-left mr-1" />
                    {t("General.back")}
                  </Button>
                </Link>
              ) : null}
            </Col>
            <Col sm="6">
              <div className="alert alert-success text-center">
                <h5 className="content-title mb-2">
                  <b>
                    {t("Assessment.completeTest")} <br />{" "}
                    {t("Assessment.personalityTitle")}.
                  </b>
                </h5>
                {result.expired_at && (
                  <p>
                    <b>{result.message}</b>
                  </p>
                )}
              </div>
            </Col>
            <Col sm="3">
              <ButtonDropdown
                isOpen={dropdownOpen}
                toggle={toggle}
                className="btn-share float-right"
              >
                <DropdownToggle
                  caret
                  className="navtext btn btn-lg btn-netis-color"
                >
                  <i className="fa fa-share-alt mr-2"></i>
                  {t("Assessment.share")}
                </DropdownToggle>
                <DropdownMenu
                  className="mr-2 mt-3"
                  style={{ marginLeft: "-120px" }}
                >
                  <DropdownItem style={{ border: "none" }}>
                    <FacebookShareButton
                      style={{ marginRight: 50 }}
                      className="sosmed text-left ml-1"
                      url={
                        process.env.REACT_APP_DOMAIN +
                        "/share-assessment-disc/" +
                        token
                      }
                      quote={
                        "Inilah hasil tes gaya kerjaku. Cek milikmu disini!"
                      }
                    >
                      <FacebookIcon
                        size={32}
                        round={true}
                        style={{ marginRight: "1rem" }}
                      />
                      <b>Facebook</b>
                    </FacebookShareButton>{" "}
                    <br />
                  </DropdownItem>
                  <DropdownItem style={{ border: "none" }}>
                    <TwitterShareButton
                      style={{ marginRight: 50 }}
                      className="sosmed text-left ml-1"
                      url={
                        process.env.REACT_APP_DOMAIN +
                        "/share-assessment-disc/" +
                        token
                      }
                      title={
                        "Inilah hasil tes gaya kerjaku. Cek milikmu disini!"
                      }
                    >
                      <TwitterIcon
                        size={32}
                        round={true}
                        style={{ marginRight: "1rem" }}
                      />
                      <b>Twitter</b>
                    </TwitterShareButton>
                    <br />
                  </DropdownItem>
                  <DropdownItem style={{ border: "none" }}>
                    <WhatsappShareButton
                      style={{ marginRight: 50 }}
                      className="sosmed text-left ml-1"
                      url={
                        process.env.REACT_APP_DOMAIN +
                        "/share-assessment-disc/" +
                        token
                      }
                      separator={
                        "Inilah hasil tes gaya kerjaku. Cek milikmu disini!"
                      }
                    >
                      <WhatsappIcon
                        size={32}
                        round={true}
                        style={{ marginRight: "1rem" }}
                      />
                      <b>WhatsApp</b>
                    </WhatsappShareButton>
                  </DropdownItem>
                  <DropdownItem style={{ border: "none" }} onClick={diKlik}>
                    <CopyToClipboard
                      className="text-left ml-2"
                      text={
                        process.env.REACT_APP_DOMAIN +
                        "/share-assessment-disc/" +
                        token
                      }
                    >
                      <div>
                        <img
                          src={require("../../../../assets/img/copy.png")}
                          alt=""
                          style={{ width: "25px" }}
                        />
                        <b style={{ cursor: "pointer", marginLeft: "20px" }}>
                          Copy Link
                        </b>
                      </div>
                    </CopyToClipboard>
                  </DropdownItem>
                </DropdownMenu>
              </ButtonDropdown>
            </Col>
          </Row>
        </div>

        <div className="text-center">
          <img
            src={require("../../../../assets/img/disc/" +
              results.type +
              ".png")}
            alt="logo"
            style={{ height: 300 }}
            className="my-4"
          />
          <h2 className="mb-3 h4 title-menu-company text-uppercase">
            <b>
              <ReactMarkdown source={results.nama.split(" (")[0]} />
            </b>
          </h2>
          <Row>
            <Col lg="3" />
            <Col lg="6">
              <blockquote className="blockquote-quote">
                <h2 style={{ lineHeight: "1.2em" }}>
                  <i style={{ fontSize: "0.6em" }}>
                    <ReactMarkdown source={'"' + results.deskriptif + '"'} />
                  </i>
                </h2>
              </blockquote>
            </Col>
            <Col lg="3" />
          </Row>
          <Row>
            <Col lg="7" md="7" sm="12" className="p-2 mx-auto">
              <Card
                className="shadow border-0 mx-auto mt-4 mb-3"
                style={{ borderRadius: "12px" }}
              >
                <CardHeader
                  style={{
                    backgroundColor: "#ffb5cc",
                    borderRadius: "12px 12px 0px 0px",
                  }}
                >
                  <h4 style={{ color: "#000" }}>
                    {t("Assessment.characteristics")}
                  </h4>
                </CardHeader>
                <CardBody
                  className="navtext text-left"
                  style={{
                    backgroundColor: "#FEF8FA",
                  }}
                >
                  {results.karakteristik.split("\n").length > 10 ? (
                    <>
                      <Row>
                        <Col>
                          <ul>
                            {karakter &&
                              karakter[0]?.map((karakterFirst) => {
                                return (
                                  <li>{karakterFirst.replace("-", "")}</li>
                                );
                              })}
                          </ul>
                        </Col>

                        <Col>
                          <ul>
                            {karakter &&
                              karakter[1]?.map((karakterSecond) => {
                                return (
                                  <li>{karakterSecond.replace("-", "")}</li>
                                );
                              })}
                          </ul>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    <ReactMarkdown source={results.karakteristik} />
                  )}
                </CardBody>
              </Card>
            </Col>
            <Col lg="7" md="7" sm="12" className="p-2 mx-auto">
              <Card
                className="shadow border-0 mx-auto mt-4 mb-3"
                style={{ borderRadius: "12px" }}
              >
                <CardHeader
                  style={{
                    backgroundColor: "#ffc9ad",
                    borderRadius: "12px 12px 0px 0px",
                  }}
                >
                  <h4 style={{ color: "#000" }}>{t("Assessment.power")}</h4>
                </CardHeader>
                <CardBody
                  className="navtext text-left"
                  style={{
                    backgroundColor: "#FEF6F1",
                  }}
                >
                  {results.kekuatan.split("\n").length > 10 ? (
                    <>
                      <Row>
                        <Col>
                          <ul>
                            {kekuatan &&
                              kekuatan[0]?.map((kekuatanFirst) => {
                                return (
                                  <li>{kekuatanFirst.replace("-", "")}</li>
                                );
                              })}
                          </ul>
                        </Col>

                        <Col>
                          <ul>
                            {kekuatan &&
                              kekuatan[1]?.map((kekuatanSecond) => {
                                return (
                                  <li>{kekuatanSecond.replace("-", "")}</li>
                                );
                              })}
                          </ul>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    <ReactMarkdown source={results.kekuatan} />
                  )}
                </CardBody>
              </Card>
            </Col>
            <Col lg="7" md="7" sm="12" className="p-2 mx-auto">
              <Card
                className="shadow border-0 mx-auto mt-4 mb-3"
                style={{ borderRadius: "12px" }}
              >
                <CardHeader
                  style={{
                    backgroundColor: "#a990e4",
                    borderRadius: "12px 12px 0px 0px",
                  }}
                >
                  <h4 style={{ color: "#000" }}>{t("Assessment.area")}</h4>
                </CardHeader>
                <CardBody
                  className="navtext text-left"
                  style={{
                    backgroundColor: "#EEE9FB",
                  }}
                >
                  {results.area_perkembangan.split("\n").length > 10 ? (
                    <>
                      <Row>
                        <Col>
                          <ul>
                            {perkembangan &&
                              perkembangan[0]?.map((perkembanganFirst) => {
                                return (
                                  <li>{perkembanganFirst.replace("-", "")}</li>
                                );
                              })}
                          </ul>
                        </Col>

                        <Col>
                          <ul>
                            {perkembangan &&
                              perkembangan[1]?.map((perkembanganSecond) => {
                                return (
                                  <li>{perkembanganSecond.replace("-", "")}</li>
                                );
                              })}
                          </ul>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    <ReactMarkdown source={results.area_perkembangan} />
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
}

export default translate(ResultAssesmentDisc);
