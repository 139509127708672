import { useMemo } from "react";
import useSWR from "swr";

export function useInvoiceBusinessInsight(defaultData = []) {
  const { data: response, error } = useSWR(
    "/assessment/test/business-insight/invoice",
    { refreshInterval: 1000000 }
  );

  const loading = !response && !error;

  const data = useMemo(() => {
    if (response) {
      return response.data;
    }
    return defaultData;
  }, [response, defaultData]);

  return { loading, data, error };
}
