import React, { useState, Fragment, useEffect } from "react";
import {
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Button,
  Spinner,
  Alert,
} from "reactstrap";
import UserProfile from "./MainProfile";
import UserDocument from "./DocumentProfile";
// import UserHistory from "./HistoryProfile.js";
// import Preferensi from "./Preferensi";
import UserMbti from "./AssessmentProfile/Mbti";
import UserPapikostik from "./AssessmentProfile/Papikostik";
import UserDisc from "./AssessmentProfile/Disc";
import UserFingerprint from "./AssessmentProfile/Fingerprint";
import UserMsdt from "./AssessmentProfile/Msdt";
import InternalModal from "../../../../components/atoms/InternalModal";
// import UserSpm from "./AssessmentProfile/Spm";
import UserBusinessInsight from "./AssessmentProfile/BusinessInsight";
import UserAgility from "./AssessmentProfile/Agility";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { translate } from "react-switch-lang";
import { requestDownload } from "../../../../utils/RequestDownload";
import request from "../../../../utils/Request";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import Tour from "reactour";
import CaptureLoading from "../../../../components/atoms/CaptureLoading";
import requestGeneral from "../../../../utils/RequestGeneral";

// SwiperCore.use([Scrollbar]);
// import SwiperCore, { Scrollbar } from 'swiper';
// import 'swiper/components/scrollbar/scrollbar.scss';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.scss";
import LoadingAnimation from "../../../../components/atoms/LoadingAnimation";
import NoUserInternalModal from "../../../../components/atoms/NoUserInternalModal";

toast.configure();
const Profile = (props) => {
  const [user] = useState(props.user);
  const [download, setDownload] = useState(false);
  const [activeTab, setActiveTab] = useState(new Array(6).fill("1"));
  const disableBody = (target) => disableBodyScroll(target);
  const enableBody = (target) => enableBodyScroll(target);
  const accentColor = "#93aad6";
  const [isTourOpen, setIsTourOpen] = useState(false);
  const toggle = (tabPane, tab) => {
    const newArray = activeTab.slice();
    newArray[tabPane] = tab;
    setActiveTab(newArray);
  };
  const [loadingPopup, setLoadingPopup] = useState(false);
  const [id, setId] = useState(null);
  const [job, setJob] = useState(null);
  const [company, setCompany] = useState(null);
  const [loading, setLoading] = useState(true);
  const [modal, setModal] = useState(true);
  const [modalSub, setModalSub] = useState(false);
  const { t } = props;
  useEffect(() => {
    requestGeneral
      .get("internal/recruitment/internal/" + localStorage.getItem("referal"))
      .then((res) => {
        setCompany(res?.data?.data?.company_name);
        setJob(res?.data?.data?.name);
        setId(res?.data?.data?.id);
        requestGeneral
          .get(
            "internal/recruitment/internal/user/" +
              localStorage.getItem("referal") +
              "/" +
              res?.data?.data?.companyPaid
          )
          .then((res) => {
            if (!res?.data?.existSkillanaId && res?.data?.isSub) {
              setModal(true);
              setLoading(false);
            } else {
              setModal(false);
              setLoading(false);
            }
          })
          .catch((err) => {
            if (err?.response?.data?.isSub) {
              setCompany(null);
              setJob(null);
              setId(null);
              localStorage.removeItem("referal");
              setModal(false);
              setModalSub(true);
              setLoading(false);
            } else {
              setModal(true);
              setLoading(false);
            }
          });
      })
      .catch((err) => {
        setModal(false);
        setLoading(false);
      });
  }, []);
  function removeInternal() {
    localStorage.removeItem("referal");
    setModal(false);
  }
  function removeInternalSub() {
    localStorage.removeItem("referal");
    setModalSub(false);
  }
  function regisInternal() {
    setLoadingPopup(true);
    requestGeneral
      .post("/internal/recruitment/vacancy/" + id)
      .then((res) => {
        setLoadingPopup(false);
        setModal(false);
        toast.success("Success");
      })
      .catch((err) => {
        setLoadingPopup(false);
        toast.error("Error");
      });
  }
  useEffect(() => {
    if (props.user) {
      if (props.user.guideProfile === 0) {
        setIsTourOpen(true);
      }
    }
  }, [props.user]);
  let tabContent;
  if (activeTab[0] === "1") {
    tabContent = <UserProfile user={user} />;
  }
  //else if (activeTab[0] === "2") {
  //   tabContent = <Preferensi user={user} />;
  // }
  else if (activeTab[0] === "2") {
    tabContent = <UserDocument user={user} />;
  }
  //else if (activeTab[0] === "4") {
  //   tabContent = <UserHistory user={user} />;
  // }
  else if (activeTab[0] === "3") {
    tabContent = <UserMbti user={user} />;
  } else if (activeTab[0] === "4") {
    tabContent = <UserDisc user={user} />;
  } else if (activeTab[0] === "5") {
    tabContent = <UserMsdt user={user} />;
  } else if (activeTab[0] === "6") {
    tabContent = <UserPapikostik user={user} />;
  } else if (activeTab[0] === "7") {
    tabContent = <UserBusinessInsight user={user} />;
  } else if (activeTab[0] === "8") {
    tabContent = <UserAgility user={user} />;
  } else if (activeTab[0] === "9") {
    tabContent = <UserFingerprint user={user} />;
  }

  console.log(activeTab[0] === "3");
  const downloadProfile = (id) => {
    setDownload(true);
    requestDownload(`/users/download-profile`).finally(() => {
      setDownload(false);
    });
  };
  const steps = [
    {
      selector: ".step-profile-1",
      content: ({ goTo, inDOM }) => (
        <div>
          <p>
            {t("UserGuide.profileComplement")} (
            <span style={{ color: "red" }}>*</span>){" "}
            {t("UserGuide.profileComplement2")}
          </p>
          <div className="col-12 text-center">
            <Row>
              <div className="col-12 text-right p-0">
                <Button
                  className="mt-2"
                  type="submit"
                  color="netis-color"
                  onClick={() => goTo(1)}
                >
                  {t("next")} <i className="fa fa-arrow-right ml-2"></i>
                </Button>
              </div>
            </Row>
          </div>
        </div>
      ),
    },
    {
      selector: ".step-profile-2",
      content: ({ goTo, inDOM }) => (
        <div>
          <p>{t("UserGuide.profileStandart")}</p>
          <div className="col-12 text-center">
            <Row>
              <div className="col-6 text-center p-0">
                <Button
                  className="mt-2"
                  type="submit"
                  color="netis-color"
                  onClick={() => goTo(0)}
                >
                  <i className="fa fa-arrow-left mr-2"></i>
                  {t("previous")}
                </Button>
              </div>
              <div className="col-6 text-center p-0">
                <Button
                  className="mt-2"
                  type="submit"
                  color="netis-color"
                  onClick={() => {
                    disableUserGuide();
                    enableBody();
                    setIsTourOpen(false);
                  }}
                >
                  {t("next")} <i className="fa fa-arrow-right ml-2"></i>
                </Button>
              </div>
            </Row>
          </div>
        </div>
      ),
    },
    // {
    //   selector: ".step-profile-69",
    //   content: ({ goTo, inDOM }) => (
    //     <div>
    //       <p>
    //         "Profil Profesional" bisa kamu lengkapi jika kamu ingin melamar
    //         pekerjaan
    //       </p>
    //       <div className="col-12 text-center">
    //         <Row>
    //           <div className="col-6 text-center p-0">
    //             <Button
    //               className="mt-2"
    //               type="submit"
    //               color="netis-color"
    //               onClick={() => goTo(1)}
    //             >
    //               <i className="fa fa-arrow-left mr-2"></i>{t('previous')}
    //             </Button>
    //           </div>
    //           <div className="col-6 text-center p-0">
    //             <Button
    //               className="mt-2"
    //               type="submit"
    //               color="netis-color"
    //               onClick={() => {
    //                 disableUserGuide();
    //                 enableBody();
    //                 setIsTourOpen(false);
    //               }}
    //             >
    //               Oke, saya paham
    //             </Button>
    //           </div>
    //         </Row>
    //       </div>
    //     </div>
    //   ),
    // },
  ];
  function disableUserGuide() {
    request
      .put("/users/disable_user_guide_profile")
      .then((res) => {
        props.setUser({
          ...props.user,
          guideProfile: 1,
        });
      })
      .catch((err) => {
        props.setUser({
          ...props.user,
          guideProfile: 1,
        });
      });
  }
  function logoutInternal() {
    localStorage.removeItem("referal");
    window.location.reload();
  }

  if (loadingPopup) {
    return <CaptureLoading title="Loading" visible={loadingPopup} />;
  }

  return (
    <div className="navtext animated fadeIn">
      {!localStorage.getItem("referal") ? (
        <Tour
          steps={steps}
          accentColor={accentColor}
          showButtons={false}
          rounded={5}
          isOpen={isTourOpen}
          disableFocusLock={false}
          onAfterOpen={disableBody}
          onBeforeClose={enableBody}
          onRequestClose={() => {
            disableUserGuide();
            setIsTourOpen(false);
          }}
        />
      ) : null}

      <Fragment>
        {loading ? (
          <LoadingAnimation />
        ) : (
          <>
            <Row className="mb-3">
              <NoUserInternalModal
                isOpen={modalSub}
                onRight={removeInternalSub}
              />
              {localStorage.getItem("referal") ? (
                <InternalModal
                  isOpen={modal}
                  company={company}
                  job={job}
                  onLeft={removeInternal}
                  onRight={regisInternal}
                />
              ) : null}
              {!localStorage.getItem("referal") ? (
                <>
                  <Col lg={4} md={4} sm={4} xs={4}>
                    <Link to="/home">
                      <Button className="btn btn-netis-color">
                        <i className="fa fa-arrow-left mr-2"></i>
                        {t("General.back")}
                      </Button>
                    </Link>
                  </Col>

                  <Col lg={3} md={3} sm={3} xs={3} className="text-center">
                    {/* TAMPILAN WEB BROWSER */}
                    <h3 className="content-title ml-5 d-none d-xl-block || d-none d-lg-block d-xl-none">
                      {t("General.profile")}
                    </h3>
                    {/* TAMPILAN MOBILE VIEW */}
                    <h3
                      className="content-title float-right d-block d-sm-none || d-none d-sm-block d-md-none || d-none d-md-block d-lg-none"
                      style={{ marginRight: "-20px" }}
                    >
                      {t("General.profile")}
                    </h3>
                  </Col>

                  <Col lg={5} md={5} sm={5} xs={5}>
                    <Button
                      className="btn btn-netis-color float-right"
                      disabled={download}
                      onClick={() => downloadProfile(user.id)}
                    >
                      {download ? (
                        <Spinner size="sm" />
                      ) : (
                        <span>
                          <i className="fa fa-download"></i>{" "}
                          {t("UserGuide.downloadCV")}
                        </span>
                      )}
                    </Button>
                  </Col>
                </>
              ) : (
                <Col sm="12" className="text-center">
                  <Alert color="info" className="text-center mt-3 mb-4">
                    <h5 className="content-title mb-2">
                      {t("Internal.alert1")} {job} {t("Internal.alert2")}{" "}
                      {company}
                    </h5>
                    <br />
                    <Button
                      className="btn button-outline-red"
                      onClick={logoutInternal}
                    >
                      <i className="fa fa-sign-out mr-2"></i>
                      {t("Internal.logout")} {job}
                    </Button>
                  </Alert>
                </Col>
              )}
            </Row>
            <Row>
              <Col xs="12" md="12" className="mb-12">
                {/* <Nav tabs> */}
                {!localStorage.getItem("referal") &&
                props.user.guideProfile === 0 ? (
                  <div style={{ display: "inherit" }}>
                    <Nav tabs>
                      <div
                        className="step-profile-1"
                        style={{ display: "inherit" }}
                      >
                        <NavItem>
                          <NavLink
                            activestyle={{ backgroundColor: "blue" }}
                            active={activeTab[0] === "1"}
                          >
                            {t("General.profile")}
                          </NavLink>
                        </NavItem>
                        {/* <NavItem>
                      <NavLink
                        activestyle={{ backgroundColor: "blue" }}
                        active={activeTab[0] === "2"}
                      >
                        {t("Preferensi Pekerjaan")}
                      </NavLink>
                    </NavItem> */}
                      </div>
                      <div
                      // className="step-profile-2"
                      // style={{ display: "inherit" }}
                      >
                        <NavItem>
                          <NavLink
                            activestyle={{ backgroundColor: "blue" }}
                            active={activeTab[0] === "2"}
                          >
                            {t("General.document")}
                          </NavLink>
                        </NavItem>
                        {/* <NavItem>
                      <NavLink
                        activestyle={{ backgroundColor: "blue" }}
                        active={activeTab[0] === "4"}
                      >
                        Riwayat
                      </NavLink>
                    </NavItem> */}
                      </div>
                      {!localStorage.getItem("referal") ? (
                        <div
                        // className="69"
                        // style={{ display: "inherit" }}
                        >
                          <NavItem>
                            <NavLink
                              activestyle={{ backgroundColor: "blue" }}
                              active={
                                activeTab[0] === "3" ||
                                activeTab[0] === "4" ||
                                activeTab[0] === "5" ||
                                activeTab[0] === "6" ||
                                activeTab[0] === "7" ||
                                activeTab[0] === "8" ||
                                activeTab[0] === "9" ||
                                activeTab[0] === "10"
                              }
                            >
                              {t("General.resultAssessment")}
                            </NavLink>
                          </NavItem>
                        </div>
                      ) : null}
                    </Nav>
                  </div>
                ) : (
                  /* MENU DI PROFIL WEB */
                  <div
                    style={{ display: "inherit" }}
                    className="d-none d-xl-block || d-none d-lg-block d-xl-none || d-none d-md-block d-lg-none"
                  >
                    <Nav tabs>
                      <div
                        className="step-profile-1"
                        style={{ display: "inherit" }}
                      >
                        <NavItem>
                          <NavLink
                            activestyle={{ backgroundColor: "blue" }}
                            active={activeTab[0] === "1"}
                            onClick={() => {
                              toggle(0, "1");
                            }}
                          >
                            {t("General.profile")}
                          </NavLink>
                        </NavItem>
                        {/* <NavItem>
                      <NavLink
                        activestyle={{ backgroundColor: "blue" }}
                        active={activeTab[0] === "2"}
                        onClick={() => {
                          toggle(0, "2");
                        }}
                      >
                        {t("Preferensi Pekerjaan")}
                      </NavLink>
                    </NavItem> */}
                      </div>
                      <div
                      // className="step-profile-2"
                      // style={{ display: "inherit" }}
                      >
                        <NavItem>
                          <NavLink
                            activestyle={{ backgroundColor: "blue" }}
                            active={activeTab[0] === "2"}
                            onClick={() => {
                              toggle(0, "2");
                            }}
                          >
                            {t("General.document")}
                          </NavLink>
                        </NavItem>
                        {/* <NavItem>
                      <NavLink
                        activestyle={{ backgroundColor: "blue" }}
                        active={activeTab[0] === "4"}
                        onClick={() => {
                          toggle(0, "4");
                        }}
                      >
                        Riwayat
                      </NavLink>
                    </NavItem> */}
                      </div>
                      {!localStorage.getItem("referal") ? (
                        <div
                        // className="69"
                        // style={{ display: "inherit" }}
                        >
                          <NavItem>
                            <NavLink
                              activestyle={{ backgroundColor: "blue" }}
                              active={
                                activeTab[0] === "3" ||
                                activeTab[0] === "4" ||
                                activeTab[0] === "5" ||
                                activeTab[0] === "6" ||
                                activeTab[0] === "7" ||
                                activeTab[0] === "8" ||
                                activeTab[0] === "9" ||
                                activeTab[0] === "10"
                              }
                              onClick={() => {
                                toggle(0, "3");
                              }}
                            >
                              {t("General.resultAssessment")}
                            </NavLink>
                          </NavItem>
                        </div>
                      ) : null}
                    </Nav>
                  </div>
                )}

                {/* SWIPER MENU DI PROFIL MOBILE */}
                <Nav
                  tabs
                  className="menuProfilemobile d-block d-sm-none || d-none d-sm-block d-md-none"
                >
                  <Swiper
                    spaceBetween={5}
                    slidesPerView={2.5}
                    // scrollbar={{ draggable: true }}
                  >
                    <SwiperSlide>
                      <NavItem style={{ marginRight: "50px" }}>
                        <NavLink
                          activestyle={{ backgroundColor: "blue" }}
                          active={activeTab[0] === "1"}
                          onClick={() => {
                            toggle(0, "1");
                          }}
                        >
                          {t("General.profile")}
                        </NavLink>
                      </NavItem>
                    </SwiperSlide>
                    {/* <SwiperSlide>
                  <NavItem style={{ marginLeft: "-50px" }}>
                    <NavLink
                      activestyle={{ backgroundColor: "blue" }}
                      active={activeTab[0] === "2"}
                      onClick={() => {
                        toggle(0, "2");
                      }}
                    >
                      {t("Preferensi Pekerjaan")}
                    </NavLink>
                  </NavItem>
                </SwiperSlide> */}
                    <SwiperSlide>
                      <NavItem>
                        <NavLink
                          activestyle={{ backgroundColor: "blue" }}
                          active={activeTab[0] === "2"}
                          onClick={() => {
                            toggle(0, "2");
                          }}
                        >
                          {t("General.document")}
                        </NavLink>
                      </NavItem>
                    </SwiperSlide>
                    {/* <SwiperSlide>
                  <NavItem>
                    <NavLink
                      activestyle={{ backgroundColor: "blue" }}
                      active={activeTab[0] === "4"}
                      onClick={() => {
                        toggle(0, "4");
                      }}
                    >
                      Riwayat
                    </NavLink>
                  </NavItem>
                </SwiperSlide> */}
                    {!localStorage.getItem("referal") ? (
                      <SwiperSlide>
                        <NavItem style={{ marginRight: "-90px" }}>
                          <NavLink
                            activestyle={{ backgroundColor: "blue" }}
                            active={
                              activeTab[0] === "3" ||
                              activeTab[0] === "4" ||
                              activeTab[0] === "5" ||
                              activeTab[0] === "6" ||
                              activeTab[0] === "7" ||
                              activeTab[0] === "8" ||
                              activeTab[0] === "9" ||
                              activeTab[0] === "10"
                            }
                            onClick={() => {
                              toggle(0, "3");
                            }}
                          >
                            {t("General.resultAssessment")}
                          </NavLink>
                        </NavItem>
                      </SwiperSlide>
                    ) : null}
                  </Swiper>
                </Nav>

                {/* ISI DARI HASIL ASESMEN */}
                {activeTab[0] === "3" ||
                activeTab[0] === "4" ||
                activeTab[0] === "5" ||
                activeTab[0] === "6" ||
                activeTab[0] === "7" ||
                activeTab[0] === "8" ||
                activeTab[0] === "9" ||
                activeTab[0] === "10" ? (
                  <Nav
                    tabs
                    style={{ border: "none", marginBottom: "-40px" }}
                    className="mt-3"
                  >
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        toggle(0, "3");
                      }}
                    >
                      <Nav tabs style={{ border: "none" }}>
                        <NavItem style={{ marginBottom: "30px" }}>
                          <NavLink
                            activestyle={{
                              color: "green",
                            }}
                            active={activeTab[0] === "3"}
                            className="nav-assessmen"
                          >
                            {t("Assessment.aptitude")}
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </div>
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        toggle(0, "4");
                      }}
                    >
                      <NavItem style={{ marginBottom: "30px" }}>
                        <NavLink
                          activestyle={{
                            color: "#fff",
                            backgroundColor: "#5a9ed6",
                          }}
                          className="nav-assessmen"
                          active={activeTab[0] === "4"}
                        >
                          {t("Assessment.personality")}
                        </NavLink>
                      </NavItem>
                    </div>
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        toggle(0, "5");
                      }}
                    >
                      <NavItem style={{ marginBottom: "30px" }}>
                        <NavLink
                          activestyle={{
                            color: "#fff",
                            backgroundColor: "#5a9ed6",
                          }}
                          className="nav-assessmen "
                          active={activeTab[0] === "5"}
                        >
                          {t("Assessment.leadership")}
                        </NavLink>
                      </NavItem>
                    </div>
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        toggle(0, "6");
                      }}
                    >
                      <NavItem style={{ marginBottom: "30px" }}>
                        <NavLink
                          activestyle={{
                            color: "#fff",
                            backgroundColor: "#5a9ed6",
                          }}
                          className="nav-assessmen "
                          active={activeTab[0] === "6"}
                        >
                          {t("Assessment.workStyle")}
                        </NavLink>
                      </NavItem>
                    </div>
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        toggle(0, "7");
                      }}
                    >
                      <NavItem style={{ marginBottom: "30px" }}>
                        <NavLink
                          activestyle={{
                            color: "#fff",
                            backgroundColor: "#5a9ed6",
                          }}
                          className="nav-assessmen "
                          active={activeTab[0] === "7"}
                        >
                          {t("Assessment.businessInsight")}
                        </NavLink>
                      </NavItem>
                    </div>
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        toggle(0, "8");
                      }}
                    >
                      <NavItem style={{ marginBottom: "30px" }}>
                        <NavLink
                          activestyle={{
                            color: "#fff",
                            backgroundColor: "#5a9ed6",
                          }}
                          className="nav-assessmen "
                          active={activeTab[0] === "8"}
                        >
                          {t("Assessment.agility")}
                        </NavLink>
                      </NavItem>
                    </div>
                    {/* <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    toggle(0, "9");
                  }}
                >
                  <NavItem style={{ marginBottom: "30px" }}>
                    <NavLink
                      activestyle={{
                        color: "#fff",
                        backgroundColor: "#5a9ed6",
                      }}
                      className="nav-assessmen "
                      active={activeTab[0] === "9"}
                    >
                      {t("Assessment.biometric")}
                    </NavLink>
                  </NavItem>
                </div> */}
                  </Nav>
                ) : null}

                <TabContent activeTab={activeTab[0]}>
                  <TabPane tabId={activeTab[0]}> {tabContent} </TabPane>
                </TabContent>
              </Col>
            </Row>
          </>
        )}
      </Fragment>
    </div>
  );
};

const mapsToDispatch = (dispatch) => {
  return {
    setUser: (state) =>
      dispatch({
        type: "SET_USER",
        payload: state,
      }),
  };
};
export default connect(
  ({ user }) => ({ user }),
  mapsToDispatch
)(translate(Profile));
