import React, { useState } from "react";
import { useEffect } from "react";
import { Alert } from "reactstrap";
import request from "../../../../../../utils/Request";
import LoadingAnimation from "../../../../../../components/atoms/LoadingAnimation";
import MbtiResult from "../../../../../Templates/ResultAssessment/ResultAssessmentMbti";
import moment from "../../../../../../utils/Moment";
import { translate } from "react-switch-lang";
function Mbti(props) {
  const { t } = props;
  const [loading, setLoading] = useState(true);
  const [result, setResult] = useState(null);
  const [message, setMessage] = useState(null);
  moment.locale("id");
  useEffect(() => {
    let id = "id";
    request
      .get("/tes/mbti?lang=" + id)
      .then((res) => {
        if (res.data?.data) {
          setResult(null);
          setLoading(false);
        }
      })
      .catch((err) => {
        if (err.response?.status === 422) {
          request.get("tes/mbti_result?lang=id").then((res) => {
            setResult(res.data.data);
            setMessage(res.data.message);
            setLoading(false);
          });
        } else {
          setLoading(false);
          return Promise.reject(err);
        }
      });
  }, []);

  if (loading) {
    return <LoadingAnimation />;
  }

  return (
    <div className="pb-5 animated fadeIn">
      {result ? (
        <MbtiResult result={result} back={false} message={message} />
      ) : (
        <Alert color="danger" className="text-center mt-3 mb-4">
          <h6 className="content-title mb-2">
            {t("Assessment.sorryNoAssessmentAptitude")}
          </h6>
        </Alert>
      )}
    </div>
  );
}

export default translate(Mbti);
