import React, { useState, useEffect } from "react";
import { Row, Col, Container, Alert } from "reactstrap";
import { useParams } from "react-router-dom";
import request from "../../../utils/Request";
import LoadingAnimation from "../../../components/atoms/LoadingAnimation";
import { translate, t } from "react-switch-lang";

import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";
function ShareableMsdt() {
  const [loading, setLoading] = useState(true);
  const [result, setResult] = useState(null);
  const { resultCodes } = useParams();
  const [isOpen, setIsOpen] = useState(null);
  useEffect(() => {
    request
      .get("/assessment/test/business-insight/share/" + resultCodes)
      .then((res) => {
        setResult(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        setResult(null);
        setLoading(false);
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  const data = [
    {
      id: 1,
      title: "Autonomy",
      text1: result ? result["autonomy"] : null,
      text2: null,
    },
    {
      id: 2,
      title: "Innovativeness",
      text1: result ? result["innovativeness"] : null,
      text2: null,
    },
    {
      id: 3,
      title: "Risk Taking",
      text1: result ? result["risk_taking"] : null,
      text2: null,
    },
    {
      id: 4,
      title: "Proactiveness",
      text1: result ? result["proactiveness"] : null,
      text2: null,
    },
    {
      id: 5,
      title: "Competitive Aggresiveness",
      text1: result ? result["competitive_agresiveness"] : null,
      text2: null,
    },
    {
      id: 6,
      title: t("General.conclusion"),
      text1: result ? result["suggestion"]["high"] : null,
      text2: result ? result["suggestion"]["low"] : null,
    },
    {
      id: 7,
      title: t("General.suggestion"),
      text1: result ? result["suggestion"]["high"] : null,
      text2: result ? result["conclusion"]["low"] : null,
    },
  ];
  return (
    <div>
      {loading ? (
        <LoadingAnimation />
      ) : result ? (
        <Container>
          <div
            className="justify-content-center"
            style={{ paddingTop: "2rem" }}
          >
            <Row>
              <Col sm="2" />
              <Col sm="8">
                <Container>
                  <h3 className="mb-5" style={{ fontWeight: "bold" }}>
                    {t("Assessment.businessInsightTestResult")}
                  </h3>
                  <div className="mb-5" sm="12">
                    <img
                      src={require("../../../assets/img/business_insight_done.png")}
                      width="100%"
                      alt="business insight done"
                    />
                  </div>
                  <Accordion allowZeroExpanded>
                    {data.map((result, idx) => (
                      <AccordionItem className="mb-3" key={idx}>
                        <AccordionItemHeading
                          onClick={() => {
                            if (isOpen === idx) {
                              setIsOpen(null);
                            } else {
                              setIsOpen(idx);
                            }
                          }}
                          className={
                            isOpen === idx
                              ? "result-accordion-heading active"
                              : "result-accordion-heading"
                          }
                        >
                          <AccordionItemButton className="d-flex flex-rows justify-content-between align-items-center p-4">
                            <b>{result.title}</b>
                            {isOpen === idx ? (
                              <i className="fa fa-chevron-up" />
                            ) : (
                              <i className="fa fa-chevron-down" />
                            )}
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel
                          className="text-left p-4"
                          style={{
                            marginTop: "-5px",
                            backgroundColor: "#F2F8FE",
                            borderRadius: "0 0 10px 10px",
                          }}
                        >
                          {result.text1}
                          <br />
                          {result.text2}
                        </AccordionItemPanel>
                      </AccordionItem>
                    ))}
                  </Accordion>
                </Container>
              </Col>
              <Col sm="2" />
            </Row>
          </div>
        </Container>
      ) : (
        <Alert color="danger" className="text-center mt-3 mb-4">
          <h5 className="content-title mb-2">{t("Assessment.noData")}</h5>
        </Alert>
      )}
    </div>
  );
}
export default translate(ShareableMsdt);
