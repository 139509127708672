import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Container,
  Button,
  DropdownItem,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  Card,
  Collapse,
  CardBody,
} from "reactstrap";
import { Link } from "react-router-dom";
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import { translate, t } from "react-switch-lang";
import request from "../../../../utils/Request";
function ResultAssessmentFingerprint({ result, back }) {
  const [token, setToken] = useState(null);
  useEffect(() => {
    request
      .get("/token/" + result.id)
      .then((res) => {
        setToken(res.data);
      })
      .catch((err) => {});
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const diKlik = () => {
    toast.success(t("JobVacancy.alreadyCopy"), { autoClose: 2000 });
  };
  const [dropdownOpen, setOpen] = useState(false);
  const toggle = () => setOpen(!dropdownOpen);
  const [collapse, setCollapse] = useState(null);
  const toggleResult = (value) => {
    setCollapse(collapse === Number(value) ? null : Number(value));
  };
  return (
    <>
      <Container className="mb-4">
        <div
          className="justify-content-center mt-4 "
          style={{ paddingTop: "2rem" }}
        >
          <Row>
            <Col sm="3">
              {back ? (
                <Link to="/assessment">
                  <Button className="navtext btn btn-lg btn-netis-color mb-3 ">
                    <i className="fa fa-arrow-left mr-1" />
                    {t("General.back")}
                  </Button>
                </Link>
              ) : null}
            </Col>
            <Col sm="6">
              <div className="alert alert-success text-center">
                <h5 className="content-title mb-2">
                  <b>
                    {t("Assessment.completeTest")} <br />{" "}
                    {t("Assessment.biometricTitle")}
                  </b>
                </h5>
              </div>
            </Col>
            <Col sm="3">
              <ButtonDropdown
                isOpen={dropdownOpen}
                toggle={toggle}
                className="btn-share float-right"
              >
                <DropdownToggle
                  caret
                  className="navtext btn btn-lg btn-netis-color"
                >
                  <i className="fa fa-share-alt mr-2"></i>
                  {t("Assessment.share")}
                </DropdownToggle>
                <DropdownMenu
                  className="mr-2 mt-3"
                  style={{ marginLeft: "-120px" }}
                >
                  <DropdownItem style={{ border: "none" }}>
                    <FacebookShareButton
                      style={{ marginRight: 50 }}
                      className="sosmed text-left ml-1"
                      url={
                        process.env.REACT_APP_DOMAIN +
                        "/share-assessment-fingerprint/" +
                        token
                      }
                      quote={
                        "Inilah hasil tes Biometrikku. Cek milikmu disini!"
                      }
                    >
                      <FacebookIcon
                        size={32}
                        round={true}
                        style={{ marginRight: "1rem" }}
                      />
                      <b>Facebook</b>
                    </FacebookShareButton>{" "}
                    <br />
                  </DropdownItem>
                  <DropdownItem style={{ border: "none" }}>
                    <TwitterShareButton
                      style={{ marginRight: 50 }}
                      className="sosmed text-left ml-1"
                      url={
                        process.env.REACT_APP_DOMAIN +
                        "/share-assessment-fingerprint/" +
                        token
                      }
                      title={
                        "Inilah hasil tes Biometrikku. Cek milikmu disini!"
                      }
                    >
                      <TwitterIcon
                        size={32}
                        round={true}
                        style={{ marginRight: "1rem" }}
                      />
                      <b>Twitter</b>
                    </TwitterShareButton>
                    <br />
                  </DropdownItem>
                  <DropdownItem style={{ border: "none" }}>
                    <WhatsappShareButton
                      style={{ marginRight: 50 }}
                      className="sosmed text-left ml-1"
                      url={
                        process.env.REACT_APP_DOMAIN +
                        "/share-assessment-fingerprint/" +
                        token
                      }
                      separator={
                        "Inilah hasil tes Biometrikku. Cek milikmu disini!"
                      }
                    >
                      <WhatsappIcon
                        size={32}
                        round={true}
                        style={{ marginRight: "1rem" }}
                      />
                      <b>WhatsApp</b>
                    </WhatsappShareButton>
                  </DropdownItem>
                  <DropdownItem style={{ border: "none" }} onClick={diKlik}>
                    <CopyToClipboard
                      className="text-left ml-2"
                      text={
                        process.env.REACT_APP_DOMAIN +
                        "/share-assessment-fingerprint/" +
                        token
                      }
                    >
                      <div>
                        <img
                          src={require("../../../../assets/img/copy.png")}
                          alt=""
                          style={{ width: "25px" }}
                        />
                        <b style={{ cursor: "pointer", marginLeft: "20px" }}>
                          Copy Link
                        </b>
                      </div>
                    </CopyToClipboard>
                  </DropdownItem>
                </DropdownMenu>
              </ButtonDropdown>
            </Col>
          </Row>
        </div>

        <div className="row text-center">
          <div className="col-sm-12">
            <br />
            <br />
            <br />
            <img
              src={require("../../../../assets/img/finger/result.png")}
              alt="fingerprint-result"
              style={{ width: "300px" }}
            />
            <br />
            <br />
            <br />
            <br />
            <br />
            <h3 className="text-center">
              <b>{t("Assessment.biometric")}</b>
            </h3>
            <br />
          </div>
          <br />
          <div className="row">
            {result &&
              result.data.map((objKategori, index) => {
                return (
                  <>
                    <div className="col-sm-2"></div>
                    <div className="col-sm-8 text-justify mb-4">
                      <Button
                        key={index}
                        onClick={() => toggleResult(index)}
                        data-event={index}
                        style={{
                          backgroundColor: "#E6F2FD",
                          paddingTop: 20,
                          width: "100%",
                          paddingBottom: 20,
                        }}
                      >
                        <Row>
                          <Col xs={10}>
                            <h5
                              style={{ margin: 0, color: "#1B568D" }}
                              className="text-left"
                            >
                              <b>{objKategori.title}</b>
                            </h5>
                          </Col>
                          <Col xs={2} className="text-right">
                            <i
                              className="fa fa-angle-down"
                              style={{
                                fontSize: 20,
                                color: "#1B568D",
                                fontWeight: "bold",
                                marginRight: 30,
                              }}
                            ></i>
                          </Col>
                        </Row>
                      </Button>
                      <Collapse isOpen={collapse === index}>
                        <Card>
                          <CardBody className="navtext text-left">
                            <ul>
                              {objKategori &&
                                objKategori.list.map((objItem, idx) => {
                                  return <li className="mb-2">{objItem}</li>;
                                })}
                            </ul>
                          </CardBody>
                        </Card>
                      </Collapse>
                    </div>
                    <div className="col-sm-2"></div>
                  </>
                );
              })}
          </div>
        </div>
      </Container>
    </>
  );
}

export default translate(ResultAssessmentFingerprint);
