import React, { useState, useRef } from "react";
import { translate, t } from "react-switch-lang";
import Question from "../../../../../components/molecules/Question";
import { Button, Progress, Container } from "reactstrap";
import { useEffect } from "react";
import request from "../../../../../utils/Request";
import LoadingAnimation from "../../../../../components/atoms/LoadingAnimation";
import { Prompt } from "react-router-dom";
import PapikostickResult from "../../../../Templates/ResultAssessment/ResultAssessmentPapikostik";
import AffirmationModal from "../../../../../components/atoms/AffirmationModal";
import ConfirmationModal from "../../../../../components/atoms/ConfirmationModal";
import requestGeneral from "../../../../../utils/RequestGeneral";
import NoComplete from "../NoCompleteProfile";
import NotFound from "../../../NotFound";
function arrayChunk(array, size = 10) {
  return Array(Math.ceil(array.length / size))
    .fill()
    .map((_, index) => index * size)
    .map((begin) => array.slice(begin, begin + size));
}

function AssesmentPapikostick(props) {
  const [source, setSource] = useState([]);
  const [loading, setLoading] = useState(true);

  const [answers, setAnswers] = useState({});

  const [page, setPage] = useState(0);
  const [analyzing, setAnalyzing] = useState(false);
  const [result, setResult] = useState(null);
  const [affirmation, setAffirmation] = useState(true);
  const [confirmation, setConfirmation] = useState(false);
  const [indicator, setIndicator] = useState(0);
  const [isNotComplete, setNotComplete] = useState(false);
  const [red, setRed] = useState(false);
  const refs = useRef([React.createRef(), React.createRef()]);
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("referal")) {
      requestGeneral
        .get("internal/recruitment/internal/" + localStorage.getItem("referal"))
        .then((res) => {
          if (
            res?.data?.data?.requirements?.filter((item) =>
              item?.toLowerCase().includes("papikostick")
            ).length === 0
          ) {
            setRedirect(true);
            setLoading(false);
          } else if (
            res?.data?.data?.requirements?.filter((item) =>
              item?.toLowerCase().includes("profile")
            ).length > 0
          ) {
            validate();
          } else {
            getPapi();
          }
        })
        .catch((err) => {
          getPapi();
        });
    } else {
      getPapi();
    }
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  function getPapi() {
    request
      .get("/assessment/test/papikostick")
      .then((res) => {
        if (res.data?.data) {
          const source = res.data.data;
          setSource(source);
          setLoading(false);
        }
      })
      .catch((err) => {
        if (
          err.response?.status === 422 &&
          err.response.data.data.error_type === "HAS_DONE_TEST"
        ) {
          const resultExpiredDateTime = new Date(
            err.response.data.data.result_expired_at
          );
          request.get("/assessment/test/papikostick/result").then((res) => {
            const resultResponse = res.data.data;
            resultResponse["expired_at"] = resultExpiredDateTime;
            resultResponse["message"] = res.data.message;
            setResult(resultResponse);
            setLoading(false);
          });
        } else {
          setLoading(false);
          return Promise.reject(err);
        }
      });
  }

  function validate() {
    requestGeneral
      .get(`/v2/users/validate-standart`)
      .then((res) => {
        getPapi();
      })
      .catch((err) => {
        if (err.response?.status === 422) {
          setNotComplete(true);
          setLoading(false);
        } else {
          setLoading(false);
          return Promise.reject(err);
        }
      });
  }

  useEffect(() => {
    let arr = [];
    // eslint-disable-next-line
    const tempContain = Object.values(answers).map((item) =>
      Object.values(item).map((value) => arr.push(value))
    );
    let filtered = arr.filter((a) => {
      return a !== undefined;
    });
    setIndicator(Math.round((filtered.length / 2 / source.length) * 200));
  }, [answers, source.length]);

  if (loading || analyzing) {
    return <LoadingAnimation />;
  }
  if (redirect) {
    return <NotFound />;
  }
  if (isNotComplete) {
    return <NoComplete />;
  }

  if (result) {
    return <PapikostickResult result={result} back={true} />;
  }

  function onAnswered(code, answer) {
    setAnswers({ ...answers, [code]: answer });
    setRed(null);
  }

  const groupedQuestions = arrayChunk(source, 5);
  const pagesLength = groupedQuestions.length;
  const canGoToNext = source
    ? groupedQuestions[page]
        .map((q) => q.code)
        .every((code) => Object.keys(answers).indexOf(code) !== -1)
    : false;

  // function nextPage() {
  //   window.scrollTo(0, 0)
  //   if (page < pagesLength && canGoToNext) {
  //     setPage(page + 1);
  //   }
  // }

  function nextPage() {
    if (page < pagesLength && canGoToNext) {
      setPage(page + 1);
      window.scroll({ top: 0, behavior: "smooth" });
    } else {
      let id;
      let idx = 0;
      groupedQuestions[page].some(function (qcode) {
        if (!answers[qcode.code]) {
          return (id = idx);
        }
        idx++;
        return false;
      });
      setRed(id);
      refs.current[id].scrollIntoView();
    }
    //window.scroll({ top: "#guide1", behavior: "smooth" });
  }

  function prevPage() {
    setRed(null);
    if (page > 0) {
      setPage(page - 1);
    }
    window.scroll({ top: 0, behavior: "smooth" });
  }

  function submitAnswers() {
    window.scrollTo(0, 0);
    setAnalyzing(true);
    request
      .post("/assessment/test/papikostick", { answers })
      .then((res) => {
        setResult(res.data.data);
      })
      .finally(() => setAnalyzing(false));
  }

  const numAnswered = Object.keys(answers).length;
  const numQuestions = source.length;
  function toggleAffirmation() {
    setAffirmation(!affirmation);
  }
  function toggleConfirmation() {
    setConfirmation(!confirmation);
  }
  return (
    <div className="navtext p-5">
      <Container>
        <Prompt
          when={numAnswered > 0 && numAnswered !== numQuestions}
          message={(location) => t("General.sureWantToLeave")}
        />
        <h3 className="h1">{t("Assessment.workStyleTitle")}</h3>
        <hr />
        <p>{t("Assessment.workStyleGuide")}</p>
        <Progress
          animated
          className="my-4"
          color={
            page === pagesLength - 1 && canGoToNext ? "success" : undefined
          }
          value={indicator}
        ></Progress>

        {groupedQuestions[page].map((question, idx) => (
          <Question
            {...question}
            key={question.code}
            onAnswered={onAnswered}
            checked={answers[question.code]}
            page={page}
            idx={idx}
            red={red}
            refs={refs}
          />
        ))}
        <div className="text-center" style={{ width: "100%" }}>
          {page > 0 && (
            <Button
              className="btn btn-lg btn-netis-color mr-5"
              onClick={prevPage}
            >
              <i className="fa fa-arrow-left mr-2"></i>
              {t("General.back")}
            </Button>
          )}
          {page < pagesLength - 1 && (
            <Button className="btn btn-lg btn-netis-color" onClick={nextPage}>
              {t("General.next")}
              <i className="fa fa-arrow-right ml-2"></i>
            </Button>
          )}
          {page === pagesLength - 1 && (
            <Button
              className="btn btn-md btn-success"
              onClick={toggleConfirmation}
              disabled={!canGoToNext}
            >
              <i className="fa fa-paper-plane mr-2"></i>
              {t("General.submit")}
            </Button>
          )}
        </div>
      </Container>
      {/* Modal Pop Up*/}
      <AffirmationModal
        isOpen={affirmation}
        title={t("General.warning") + " !"}
        desc={t("Assessment.warningDesc")}
        titleButton={t("General.okey")}
        toggleOpen={toggleAffirmation}
        confirmation={toggleAffirmation}
      />
      <ConfirmationModal
        isOpen={confirmation}
        title={t("General.areYouSure")}
        desc={t("Assessment.sureAssessment")}
        titleLeft={t("General.back")}
        titleRight={t("General.submit")}
        onPressLeft={toggleConfirmation}
        onPressRight={submitAnswers}
      />
    </div>
  );
}

export default translate(AssesmentPapikostick);
