import { SET_LOADER, SET_USER } from "../Actions/ui.js";
import { API_SUCCESS, API_ERROR } from "../Actions/api.js";
import { LOGOUT } from "../Actions/auth.js";

export default (
  state = {
    user: {},
    isAuthUser: "",
    isLoading: true,
    error: null,
  },
  action
) => {
  switch (action.type) {
    case API_SUCCESS:
      localStorage.setItem("session", action.payload.session_id);

      // window.location.reload();
      return { ...state, isAuthUser: true, user: action.payload, error: null };
    case API_ERROR:
      return { ...state, isAuthUser: false, error: "Login Salah" };
    case SET_USER:
      if (action.payload == null) {
        return { ...state, user: action.payload, isAuthUser: false };
      } else {
        return { ...state, user: action.payload, isAuthUser: true };
      }
    case SET_LOADER:
      return { ...state, isLoading: action.payload };
    case LOGOUT:
      localStorage.removeItem("referal");
      localStorage.removeItem("req");
      return { ...state, isAuthUser: false, user: {}, error: null };
    default:
      return { ...state };
  }
};
