import axios from "axios";
import {
  API_REQUEST_LOGIN,
  apiError,
  apiSuccess,
  API_REQUEST_LOGOUT,
} from "../Actions/api.js";
import { LOGOUT } from "../Actions/auth.js";
import { toast } from "react-toastify";
import request from "../../../utils/Request";
import langUtils from "../../../utils/SettingLanguage/index";
import { t } from "react-switch-lang";
export const authMiddleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);
    if (action.type === API_REQUEST_LOGIN) {
      dispatch({
        type: "SET_LOADER",
        payload: true,
      });
      const { url, method, data } = action.meta;
      axios({
        method,
        url,
        data,
      })
        .then(({ data }) => {
          // localStorage.setItem("language", data.language.toUpperCase());
          langUtils.setLanguage(data.language.toUpperCase());
          dispatch(apiSuccess({ response: data }));
          dispatch({
            type: "SET_LOADER",
            payload: false,
          });
        })
        .catch((error) => {
          dispatch(apiError({ error }));
          toast.error(t("Login.error"), {
            autoClose: 3000,
          });
          dispatch({
            type: "SET_LOADER",
            payload: false,
          });
          // setTimeout(() => {
          //     window.location.reload()
          // }, 3000);
        });
    }
    if (action.type === API_REQUEST_LOGOUT) {
      const { url } = action.meta;
      request
        .post(url)
        .then(() => {
          dispatch(LOGOUT);
        })
        .catch((error) => {
          dispatch(apiError({ error }));
        })
        .finally(() => localStorage.removeItem("session"));
    }
  };
