import React, { useState } from "react";
import { useEffect } from "react";
import { Alert } from "reactstrap";
import request from "../../../../../../utils/Request";
import LoadingAnimation from "../../../../../../components/atoms/LoadingAnimation";
import BusinessInsightResult from "../../../../../Templates/ResultAssessment/ResultAssessmentBusinessInsight";
import moment from "../../../../../../utils/Moment";
import { translate } from "react-switch-lang";
function BusinessInsight(props) {
  const { t } = props;
  const [loading, setLoading] = useState(true);
  const [result, setResult] = useState(null);
  moment.locale("id");
  useEffect(() => {
    request
      .get("/assessment/test/business-insight")
      .then((res) => {
        if (res.data?.data) {
          setResult(null);
          setLoading(false);
        }
      })
      .catch((err) => {
        if (err?.response?.status === 422) {
          request
            .get("/assessment/test/business-insight/result")
            .then((res) => {
              const resultResponse = res.data.data;
              resultResponse["message"] = res.data.message;
              setResult(resultResponse);
              setLoading(false);
            });
        } else {
          setLoading(false);
          return Promise.reject(err);
        }
      });
  }, []);

  if (loading) {
    return <LoadingAnimation />;
  }

  return (
    <div className="pb-5 animated fadeIn">
      {result ? (
        <BusinessInsightResult
          result={result}
          back={false}
          message={result.message}
        />
      ) : (
        <Alert color="danger" className="text-center mt-3 mb-4">
          <h6 className="content-title mb-2">
            {t("Assessment.sorryNoAssessmentBusinessInsight")}
          </h6>
        </Alert>
      )}
    </div>
  );
}

export default translate(BusinessInsight);
