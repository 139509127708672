import React, { useState, Fragment } from "react";
import { Container, Button, Row, Col, Spinner, Alert } from "reactstrap";
import { toast } from "react-toastify";
import request from "../../../../utils/Request";
import { Field, Form, Formik } from "formik";
import { Link } from "react-router-dom";
import FormikInput from "../../../../utils/FormikInput";
import { translate } from "react-switch-lang";
import langUtils from "../../../../utils/SettingLanguage/index";
toast.configure();
const API_DOMAIN = process.env.REACT_APP_DOMAIN;

export default translate(function ResetPassword(props) {
  console.log(langUtils.getLanguage());
  const { t } = props;
  const token = props.match.params.token;
  const [success, setSuccess] = useState(false);
  // const validator = new RegExp(/^(?=.*\d)(?=.*[a-z]).{8,100}$/);
  const formValues = { password: "", passwordConfirmation: "" };

  const formValidate = (values) => {
    var patternPassword = new RegExp(
      /^(?=.*[0-9]+.*)(?=.*[a-zA-Z]+.*)[0-9a-zA-Z]{8,20}$/
    );
    const errors = {};
    if (!values.password.trim()) {
      errors.password = t("General.cannotEmpty");
    }
    if (values.password !== values.passwordConfirmation) {
      errors.passwordConfirmation = t("Register.notMatchPassword");
    }
    if (!patternPassword.test(values.password)) {
      errors.password = t("Register.passwordMinimum");
    }
    if (!patternPassword.test(values.passwordConfirmation)) {
      errors.passwordConfirmation = t("Register.passwordMinimum");
    }

    return errors;
  };
  const formSubmit = (values, { setSubmitting, setErrors }) => {
    var patternPassword = new RegExp(
      /^(?=.*[0-9]+.*)(?=.*[a-zA-Z]+.*)[0-9a-zA-Z]{8,20}$/
    );
    const { password } = values;

    if (!patternPassword.test(values.password)) {
      toast.error(t("Register.passwordMinimum"));
      setSubmitting(false);
      return false;
    }
    if (!patternPassword.test(values.passwordConfirmation)) {
      toast.error(t("Register.passwordMinimum"));
      setSubmitting(false);
    }
    if (values.password !== values.passwordConfirmation) {
      toast.error(t("Register.notMatchPassword"));
      setSubmitting(false);
    } else {
      request
        .post(`${API_DOMAIN}/api/auth/newpassword/verify`, {
          token,
          password,
        })
        .then((res) => {
          // TODO: Request Api Reset Password
          toast.info(res.data.message);
          setSuccess(true);
        })
        .catch((err) => {
          if (err.response?.status === 422) {
            setErrors(err.response.data.errors);
          }
        })
        .finally(() => {
          setSubmitting(false);
        });
    }
  };
  return (
    <div className="app flex-row align-items-center">
      <Container>
        <Row className="justify-content-center">
          <Col md="4"></Col>
          <Col md="4">
            <Formik
              initialValues={formValues}
              validate={formValidate}
              onSubmit={formSubmit}
              render={({ submitForm, isSubmitting, values }) => (
                <Form>
                  <div className="logo text-center">
                    <img
                      src={require("../../../../assets/img/logo.png")}
                      style={{ width: 180 }}
                      alt="logo"
                    />
                  </div>
                  {success ? (
                    <Alert color="info" className="text-center mt-3">
                      <strong>Success</strong>
                      <br />
                      {t("successReset")}
                      <br />
                      <Link to="/login">
                        <Button color="netis-color">
                          {t("General.goToBack")} Login{" "}
                          <i className="ml-2 fa fa-chevron-right"></i>
                        </Button>
                      </Link>
                    </Alert>
                  ) : (
                    <Fragment>
                      <Field
                        type="password"
                        label={t("ResetPassword.makeNewPassword")}
                        name="password"
                        id="password"
                        component={FormikInput}
                      ></Field>
                      <Field
                        type="password"
                        label={t("General.repassword")}
                        name="passwordConfirmation"
                        id="passwordConfirmation"
                        component={FormikInput}
                      ></Field>
                      <Button
                        type="submit"
                        className="login-submit"
                        disable={isSubmitting}
                      >
                        {isSubmitting ? (
                          <span>
                            <Spinner size="sm" className="mr-2" />{" "}
                            {t("ResetPassword.makeNewPassword")}{" "}
                          </span>
                        ) : (
                          t("ResetPassword.makeNewPassword")
                        )}
                      </Button>
                      <div className="mt-5">
                        <Link to="/login">
                          <i className="mr-2 fa fa-chevron-left"></i>{" "}
                          {t("General.goToBack")} Login
                        </Link>
                      </div>
                    </Fragment>
                  )}
                </Form>
              )}
            />
          </Col>
          <Col md="4"></Col>
        </Row>
      </Container>
    </div>
  );
});
