import React, { useState } from "react";
import { Row, Col, Card } from "reactstrap";
import { translate, t } from "react-switch-lang";
import UserGuide from "../Test/UserGuide";
import { useResultTestContext } from "./AgilityTestContext";
import { LeftLargeRibbon, RibbonContainer } from "react-ribbons";
import { Prompt } from "react-router-dom";
import request from "../../../../utils/Request";
import AffirmationModal from "../../../../components/atoms/AffirmationModal";
import CaptureLoading from "../../../../components/atoms/CaptureLoading";
import { toast } from "react-toastify";

import ic_citraleka from "../../../../assets/img/agility/ic_citraleka.png";
import ic_etung from "../../../../assets/img/agility/ic_etung.png";
import ic_pathway_game from "../../../../assets/img/agility/ic_pathway_game.png";
import ic_sacit from "../../../../assets/img/agility/ic_sacit.png";
import ic_sadana from "../../../../assets/img/agility/ic_sadana.png";
import ic_sankarta from "../../../../assets/img/agility/ic_sankarta.png";
import ic_vacana from "../../../../assets/img/agility/ic_vacana.png";
import ic_bedhe_aksara from "../../../../assets/img/agility/ic_bedhe.png";

import ic_userGuide_citraleka from "../../../../assets/img/agility/userGuide/ic_userGuide_citraleka.png";
import ic_userGuide_etung from "../../../../assets/img/agility/userGuide/ic_userGuide_etung.png";
import ic_userGuide_pathway_game from "../../../../assets/img/agility/userGuide/ic_userGuide_pathway_game.png";
import ic_userGuide_sacit from "../../../../assets/img/agility/userGuide/ic_userGuide_sacit.png";
import ic_userGuide_sadana from "../../../../assets/img/agility/userGuide/ic_userGuide_sadana.png";
import ic_userGuide_sankarta from "../../../../assets/img/agility/userGuide/ic_userGuide_sankarta.png";
import ic_userGuide_vacana from "../../../../assets/img/agility/userGuide/ic_userGuide_vacana.png";
import ic_userGuide_bedhe_aksara from "../../../../assets/img/agility/userGuide/ic_userGuide_bedhe_aksara.png";
toast.configure();
function Menu(props) {
  // eslint-disable-next-line
  const [result, setResult] = useResultTestContext({});
  const [page, setPage] = useState(0);
  const [imageUserGuide, setImageUserGuide] = useState(null);
  const [nextPage, setNextPage] = useState(false);
  const [isNotComplete, setIsNotComplete] = useState(false);
  const [title, setTitle] = useState(null);
  const [userGuide, setUserGuide] = useState(null);
  const [name, setName] = useState(null);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  console.log(result);
  if (loading) {
    return <CaptureLoading title="Loading" visible={loading} />;
  }
  const menu = [
    {
      title: "Sadana",
      desc: t("Agility.sadanaDesc"),
      image: ic_sadana,
      imageUserGuide: ic_userGuide_sadana,
      name: "sadana",
      userGuide: [t("Agility.sadanaGuide1"), t("Agility.sadanaGuide2")],
    },
    {
      title: "Etung",
      desc: t("Agility.etungDesc"),
      image: ic_etung,
      imageUserGuide: ic_userGuide_etung,
      name: "etung",
      userGuide: [
        t("Agility.etungGuide1"),
        t("Agility.etungGuide2"),
        t("Agility.etungGuide3"),
        t("Agility.etungGuide4"),
      ],
    },
    {
      title: "Sankarta",
      desc: t("Agility.sankartaDesc"),
      image: ic_sankarta,
      imageUserGuide: ic_userGuide_sankarta,
      name: "sankarta",
      userGuide: [t("Agility.sankartaGuide1")],
    },
    {
      title: "Sacit",
      desc: t("Agility.sacitDesc"),
      image: ic_sacit,
      imageUserGuide: ic_userGuide_sacit,
      name: "sacit",
      userGuide: [t("Agility.sacitGuide1"), t("Agility.sacitGuide2")],
    },
    {
      title: "Citraleka",
      desc: t("Agility.citralekaDesc"),
      image: ic_citraleka,
      imageUserGuide: ic_userGuide_citraleka,
      name: "citraleka",
      userGuide: [t("Agility.citralekaGuide1")],
    },
    {
      title: "Bedhe Aksara",
      desc: t("Agility.bedheDesc"),
      image: ic_bedhe_aksara,
      imageUserGuide: ic_userGuide_bedhe_aksara,
      name: "bedhe_aksara",
      userGuide: [
        t("Agility.bedheGuide1"),
        t("Agility.bedheGuide2"),
        t("Agility.bedheGuide3"),
      ],
    },
    {
      title: "Pathway Game",
      desc: t("Agility.pathwayDesc"),
      image: ic_pathway_game,
      imageUserGuide: ic_userGuide_pathway_game,
      name: "pathway_game",
      userGuide: [t("Agility.pathwayGuide1"), t("Agility.pathwayGuide2")],
    },
    {
      title: "Vacana",
      desc: t("Agility.vacanaDesc"),
      image: ic_vacana,
      imageUserGuide: ic_userGuide_vacana,
      name: "vacana",
      userGuide: [t("Agility.vacanaGuide1")],
    },
  ];

  function goToUserGuide(title, userGuide, name, image, imageUserGuide) {
    setPage(page + 1);
    setNextPage(true);
    setTitle(title);
    setUserGuide(userGuide);
    setName(name);
    setImageUserGuide(imageUserGuide);
  }

  function submitAnswer() {
    setLoading(true);
    let complete = 1;
    Object.values(result).map((data) => {
      if (data === null) {
        complete = 0;
      }
      return true;
    });
    if (complete === 1) {
      request
        .post("/assessment/test/agility", {
          answer: result,
        })
        .then((res) => {
          window.scroll({ top: 0, behavior: "smooth" });
          props.setComplete(true);
          toast.success("Success", {
            autoClose: 3000,
          });
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setError(true);
          setLoading(false);
        });
    } else {
      setIsNotComplete(true);
      setLoading(false);
    }
  }

  function isError() {
    if (error) {
      return (
        <AffirmationModal
          isOpen={error}
          title={"Error !"}
          desc={t("Agility.errorConnection")}
          titleButton={t("General.okey")}
          toggleOpen={() => {
            setError(true);
          }}
          confirmation={() => {
            setError(false);
          }}
        />
      );
    }
  }

  function isNotCompleteFunction() {
    if (isNotComplete) {
      return (
        <AffirmationModal
          isOpen={isNotComplete}
          title={"Oops !"}
          desc={t("Agility.notComplete")}
          titleButton={t("General.okey")}
          toggleOpen={() => {
            setIsNotComplete(true);
          }}
          confirmation={() => {
            setIsNotComplete(false);
          }}
        />
      );
    }
  }

  return (
    <>
      {isNotCompleteFunction()}
      {isError()}
      <Prompt
        when={(window.onbeforeunload = () => true)}
        message={(location) => t("Apakah kamu yakin ingin keluar ?")}
      />
      {nextPage ? (
        <UserGuide
          page={page}
          title={title}
          userGuide={userGuide}
          name={name}
          setNextPage={setNextPage}
          image={imageUserGuide}
        />
      ) : (
        <div className="pt-5 pb-5">
          <h2>
            <b>Learning Agility</b>
          </h2>
          <hr />
          <br />
          <Row>
            {menu.map((data, idx) => {
              return (
                <Col
                  sm="4"
                  className="mb-4 "
                  key={idx}
                  onClick={
                    result[data.name]
                      ? null
                      : () =>
                          goToUserGuide(
                            data.title,
                            data.userGuide,
                            data.name,
                            data.image,
                            data.imageUserGuide
                          )
                  }
                >
                  <RibbonContainer className="custom-class">
                    <Card
                      className={
                        result[data.name]
                          ? "box-agility-complete"
                          : "box-agility"
                      }
                    >
                      {" "}
                      {result[data.name] ? (
                        <LeftLargeRibbon backgroundColor="#20c997" color="#fff">
                          <b>Completed</b>
                        </LeftLargeRibbon>
                      ) : null}
                      <div className="box-image text-right">
                        <img
                          src={data.image}
                          style={{
                            height: "125px",
                          }}
                          alt="icon_agility"
                        />
                      </div>
                      <h4>
                        <b>{data.title}</b>
                      </h4>
                      <p>{data.desc}</p>
                    </Card>
                  </RibbonContainer>
                </Col>
              );
            })}
            <Col
              sm="4"
              className="mb-4 box-button-submit-agility"
              onClick={submitAnswer}
            >
              <h2 className="text-submit-agility m-0">
                Submit <i className="fa fa-arrow-circle-right ml-1"></i>
              </h2>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
}

export default translate(Menu);
