import React from "react";
import { Nav, Container, Button } from "reactstrap";
import PropTypes from "prop-types";
import { AppNavbarBrand } from "@coreui/react";
import { connect } from "react-redux";
import { logout } from "../../../../../config/Services/Actions/auth";
import { translate } from "react-switch-lang";
import logo from "../../../../../assets/img/logo_blue.png";
import icon from "../../../../../assets/img/logo_blue.png";
import { Link } from "react-router-dom";
const DefaultGeneralHeader = (props) => {
  // eslint-disable-next-line
  const { t } = props;
  return (
    <>
      <Container>
        <Link to="/">
          <AppNavbarBrand
            className="mr-auto"
            full={{ src: logo, alt: "Skillana Logo", width: 130 }}
            minimized={{ src: icon, alt: "Skillana Icon", width: 120 }}
            style={{ cursor: "pointer" }}
          />
        </Link>
      </Container>

      <Nav className="ml-auto" navbar>
        <Link to="/login">
          <Button className="btn-akun" style={{ border: "none" }}>
            {t("General.account")}
          </Button>
        </Link>
      </Nav>
    </>
  );
};

DefaultGeneralHeader.propTypes = {
  t: PropTypes.func.isRequired,
};

export default connect(({ user }) => ({ user }), { logout })(
  translate(DefaultGeneralHeader)
);
